import React, { useEffect, useState } from 'react'
import './css/CollectionList.css'

import { useParams } from 'react-router-dom'
import Item from '../Components/Item/Item'

const CollectionList = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const {collection} = useParams();
    // const collectionName = collection ? collection.replace(/-/g, ' ') : null;
    const [products, setProducts] = useState([]);
    
    const [collectionName, setCollectionName] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (collection) {
            fetch(`${api_url}/allcollections`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
            })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    const collectionData = data.collections.find((item) => item.name.replace(/\s/g, '').replace(/-/g, '').toLowerCase() === collection.replace(/\s/g, '').replace(/-/g, '').toLowerCase());
                    setCollectionName(collectionData ? collectionData.name : null);
                }
            });
            fetch(`${api_url}/getproductsbycollection`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({collection: collection}),
            })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setProducts(data.products);
                }
            })
        }
    }, [collection]);

  return (
    <div className='collectionList'>
        <div className="collection-head">
            <h1 className='collection-title'>{collectionName ? collectionName : 'Collection'}</h1>
        </div>
        <div className="products">
            {products && products.length > 0 ?
            <div className="items">
            {products.map((item,i)=>{
                return <Item key={i} pid={item.pid} name={item.name} description={item.description} images={item.images} colors={item.colors}/>
            })}
            </div> :
            <div className="message">
                <h4>{`Oops, Nothing is here. Please come back later :)`}</h4>
            </div>}
        </div>
    </div>
  )
}

export default CollectionList
