import React from 'react';
import { useParams } from 'react-router-dom';

import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';

const Product = () => {
  const {productId} = useParams();

  return (
    <div>
      <ProductDisplay pid={productId}/>
    </div>
  )
}

export default Product
