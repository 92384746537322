import React, { useRef, useEffect } from 'react';
import './ComingSoon.css';

import Typewriter from 'typewriter-effect';

const ComingSoon = () => {
  const comingTextRef = useRef(null);
  const soonTextRef = useRef(null);
  const containerRef = useRef(null);

  const adjustFontSize = (textRef) => {
    if (textRef.current && containerRef.current) {
      let minFontSize = 1; // Minimum font size
      let maxFontSize = 17.8; // Initial maximum font size in viewport units
      let currentFontSize = maxFontSize;

      // Binary search to find the optimal font size
      while (minFontSize <= maxFontSize) {
        currentFontSize = (minFontSize + maxFontSize) / 2;
        textRef.current.style.fontSize = currentFontSize + 'vw';

        if (textRef.current.offsetWidth > containerRef.current.offsetWidth) {
          maxFontSize = currentFontSize - 0.1; // Decrease font size
        } else {
          minFontSize = currentFontSize + 0.1; // Increase font size
        }
      }

      // Set the final font size
      textRef.current.style.fontSize = currentFontSize + 'vw';
    }
  };

  useEffect(() => {
    // Adjust font size for both text elements
    adjustFontSize(comingTextRef);
    adjustFontSize(soonTextRef);

    // Adjust font size on window resize
    const handleResize = () => {
      adjustFontSize(comingTextRef);
      adjustFontSize(soonTextRef);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="coming-soon" ref={containerRef}>
      <h1 ref={comingTextRef}>
      <Typewriter
                        options={{
                            strings: [
                            "Coming",
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 200,
                            pauseFor: 100000,
                            deleteSpeed: 50,
                        }}
                        />
      </h1>
      <div className="middle-text">
        <span>Grunge Collection</span>
      </div>
      <h1 ref={soonTextRef}>
      <Typewriter
                        options={{
                            strings: [
                            "Soon",
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 200,
                            pauseFor: 100000,
                            deleteSpeed: 50,
                        }}
                        />
      </h1>
    </div>
  );
};

export default ComingSoon;
