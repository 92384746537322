import React, { useEffect } from 'react'
import './FeaturedProducts.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

import product1 from '../Assets/Mockups/transparent/1.png';
import product2 from '../Assets/Mockups/transparent/2.png';
import product3 from '../Assets/Mockups/transparent/3.png';
import product4 from '../Assets/Mockups/transparent/4.png';
import product5 from '../Assets/Mockups/transparent/5.png';
import product6 from '../Assets/Mockups/transparent/6.png';

const FeaturedProducts = () => {
    useEffect(() => {
        AOS.init();
    }, []);
  return (
    // reverse the aos animation
    <div className='featured-products' data-aos="fade-up" data-aos-duration="2500" data-aos-once="true">
        <div className="section-head">
            <h1 className='section-title'>Featured Products</h1>
            {/* <h3 className='section-description'>Discover our featured products that are loved by our customers.</h3> */}
        </div>
        <div className="products">
            <div className="frame frame1">
                <div className="item item1">
                    <img src={product1} alt="product1" />
                </div>
                <div className="item item2">
                    <img src={product3} alt="product3" />
                </div>
            </div>
            <div className="frame frame2">
                <div className="item item3">
                    <img src={product3} alt="product2" />
                </div>
                <div className="item item4">
                    <img src={product4} alt="product4" />
                </div>
            </div>
            <div className="frame frame3">
                <div className="item item5">
                    <img src={product3} alt="product5" />
                </div>
                <div className="item item6">
                    <img src={product4} alt="product6" />
                </div>
            </div>
        </div>
        <div className="mobile-products hide">
            <div className="frame frame1">
                <div className="item item1">
                    <img src={product1} alt="product1" />
                </div>
                <div className="item item2">
                    <img src={product3} alt="product3" />
                </div>
                <div className="item item3">
                    <img src={product3} alt="product2" />
                </div>
            </div>
            <div className="frame frame2">
                <div className="item item4">
                    <img src={product4} alt="product4" />
                </div>
                <div className="item item5">
                    <img src={product4} alt="product5" />
                </div>
                <div className="item item6">
                    <img src={product3} alt="product6" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default FeaturedProducts














// import React from 'react'
// import './FeaturedProducts.css'

// import product1 from '../Assets/Mockups/transparent/1.png';
// import product2 from '../Assets/Mockups/transparent/2.png';
// import product3 from '../Assets/Mockups/transparent/3.png';
// import product4 from '../Assets/Mockups/transparent/4.png';
// import product5 from '../Assets/Mockups/transparent/5.png';
// import product6 from '../Assets/Mockups/transparent/6.png';

// const FeaturedProducts = () => {
//   return (
//     <div className='featured-products'>
//         <div className="section-head">
//             <h1 className='section-title'>Featured Products</h1>
//             {/* <h3 className='section-description'>Discover our featured products that are loved by our customers.</h3> */}
//         </div>
//         <div className="products">
//             <div className="frame-left frame main-frame">
//                 <div className="item item1" style={{ backgroundImage: `url(${product1})` }}>
//                 </div>
//                 <div className="item item2" style={{ backgroundImage: `url(${product3})` }}>
//                 </div>
//             </div>
//             <div className="frame-right frame main-frame">
//                 <div className="sub-frame-top frame sub-frame">
//                     <div className="item item3" style={{ backgroundImage: `url(${product2})` }}>
//                     </div>
//                 </div>
//                 <div className="sub-frame-bottom frame sub-frame">
//                     <div className="sub-frame-bottom-left frame sub-frame">
//                         <div className="item item4" style={{ backgroundImage: `url(${product4})` }}>
//                         </div>
//                     </div>
//                     <div className="sub-frame-bottom-right frame sub-frame">
//                         <div className="item item5" style={{ backgroundImage: `url(${product5})` }}>
//                         </div>
//                         <div className="item item6" style={{ backgroundImage: `url(${product6})` }}>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default FeaturedProducts
