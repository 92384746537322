import React, { createContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import all_product from "../Components/Assets/Products/all_product";

export const ShopContext =  createContext(null);


const ShopContextProvider = (props) => {
  const api_url = process.env.REACT_APP_API_URL;
  const api_key = process.env.REACT_APP_API_KEY;
  const client_id = process.env.REACT_APP_API_CLIENT_ID;
  
  const {isAuthenticated, cid} = useAuth();

  const [cartItems, setCartItems] = useState(()=>{
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (savedCartItems) {
      return savedCartItems;
    }
    else
    {
      return [];
    }
  });

  useEffect(() => {
    if(isAuthenticated)
    {
      fetch(`${api_url}/getcart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': api_key,
          'x-client-id': client_id,
        },
        body: JSON.stringify({cid: cid})
      })
      .then(response => response.json())
      .then(data => {
        setCartItems(data.cartData);
      });
    }
    else
    {
      // Load cart items from local storage
      const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));
      if (savedCartItems) {
        setCartItems(savedCartItems);
      }
      else
      {
        setCartItems([]);
      }
    }
  }, [isAuthenticated]);

  const addToCart = (pid, color, size, quantity = 1) => {
    setCartItems(prevCartItems => {
      // Check if the item already exists in the cart
      const itemIndex = prevCartItems.findIndex(item => item.pid == pid && item.color.name == color.name && item.size == size);
      if (itemIndex >= 0) {
          // Update the quantity of the existing item
          const updatedCartItems = [...prevCartItems];
          updatedCartItems[itemIndex].quantity += quantity;
          return updatedCartItems;
      } else {
          // Add the new item to the cart
          const newItem = {
              pid,
              color,
              size,
              quantity,
              date_added: new Date()
          };
          return [...prevCartItems, newItem];
      }
    });
    if (isAuthenticated) {
      fetch(`${api_url}/addtocart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': api_key,
          'x-client-id': client_id,
        },
        body: JSON.stringify({cid: cid, pid: pid, color: color, size: size, quantity: quantity})
      })
      .then(response => response.json())
      .then(data => {
      });
    }
  }

  useEffect(() => {
    // Save cart items to local storage
    if (cartItems && cartItems.length > 0){
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }
    else{
      localStorage.setItem('cartItems', JSON.stringify([]));
    }
  }, [cartItems]);

  const removeFromCart = (pid, color, size, currentQuantity = null, quantity = 0) =>{
    if (quantity === 0) {
      setCartItems(prevCartItems => {
        // Filter out the item to be removed
        return prevCartItems.filter(item => !(item.pid === pid && item.color === color && item.size === size));
      });

      if (isAuthenticated) {
        fetch(`${api_url}/removefromcart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key,
            'x-client-id': client_id,
          },
          body: JSON.stringify({cid: cid, pid: pid, color: color, size: size})
        })
        .then(response => response.json())
        .then(data => {
        });
      }
    }
    // reduce quantity if quantity is greater than 0
    else{
      if (currentQuantity === 1) {
        removeFromCart(pid, color, size);
      }
      else{
        setCartItems(prevCartItems => {
          // Check if the item already exists in the cart
          const itemIndex = prevCartItems.findIndex(item => item.pid == pid && item.color.name == color.name && item.size == size);
          if (itemIndex >= 0) {
              // Update the quantity of the existing item
              const updatedCartItems = [...prevCartItems];
              updatedCartItems[itemIndex].quantity -= quantity;
              return updatedCartItems;
          }
        });
        if (isAuthenticated) {
          fetch(`${api_url}/removefromcart`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': api_key,
              'x-client-id': client_id,
            },
            body: JSON.stringify({cid: cid, pid: pid, color: color, size: size, quantity: quantity})
          })
          .then(response => response.json())
          .then(data => {
          });
        }
      }
    }
  }

  const getTotalCartAmount = () => {
      let totalAmount = 0;
      for (const item in cartItems) {
        if (cartItems[item] > 0) {
          totalAmount += cartItems[item].quantity * cartItems[item].color.sizes.find(size => size.size === cartItems[item].size).sellingPrice;
        }
      }
      return totalAmount;
  }

    const getTotalCartItems = () =>{
      let totalItems = 0;
      if (cartItems) {
        totalItems = cartItems.length;
      }
      return totalItems;
    }

  const contextValue = {getTotalCartItems,getTotalCartAmount,all_product,cartItems,addToCart,removeFromCart};
  return (
      <ShopContext.Provider value={contextValue}>
          {props.children}
      </ShopContext.Provider>
  )
}

export default ShopContextProvider;