import React, { useEffect, useState } from 'react';
import './Orders.css';

import useAuth from '../../hooks/useAuth';
import { load } from '@cashfreepayments/cashfree-js';

const Orders = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const api_key = process.env.REACT_APP_API_KEY;
  const client_id = process.env.REACT_APP_API_CLIENT_ID;
  const frontend_url = process.env.REACT_APP_FRONTEND_URL;
  const cashfree_mode = process.env.REACT_APP_CASHFREE_MODE;
  const cashfreeRedirectTarget = process.env.REACT_APP_CASHFREE_REDIRECT_TARGET;

  const {isAuthenticated, cid} = useAuth();
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (cid) {
      fetch(`${api_url}/getcustomerorders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': api_key,
          'x-client-id': client_id,
        },
        body: JSON.stringify({ cid }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setOrders(data.orders);
        }
      });
    }
  }, [cid]);

  useEffect(() => {
    const fetchProducts = async () => {
      const uniquePids = Array.from(
        new Set(orders.flatMap(order => order.order_items.map(item => item.pid)))
      );
      const productPromises = uniquePids.map(pid =>
        fetch(`${api_url}/getproductbypid`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key,
            'x-client-id': client_id,
          },
          body: JSON.stringify({ pid }),
        }).then(response => response.json())
      );

      const productsData = await Promise.all(productPromises);
      const successfulProducts = productsData
        .filter(data => data.success)
        .map(data => data.product);
      setProducts(successfulProducts);
    };

    if (orders.length > 0) {
      fetchProducts();
    }
  }, [orders]);

  const handleViewOrder = (oid) => {
    if (!isAuthenticated) {
      window.location.href = '/login';
      return;
    }
    window.location.href = `/order/${oid}`;
  };

  const viewProduct = (pid) => {
    window.location.href = `/product/${pid}`;
  };

  const retryPayment = async (oid) => {
    const cashfree = await load({
      mode: cashfree_mode,
    });

    console.log(frontend_url);
    fetch(`${api_url}/verifyorder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': api_key,
        'x-client-id': client_id,
      },
      body: JSON.stringify({ orderId: oid }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        cashfree.checkout({
          paymentSessionId: data.payment_session_id,
          redirectTarget: cashfreeRedirectTarget,
          returnUrl: `${frontend_url}/paymentconfirmation?orderId=${oid}&paymentMode=upi`,
    
        }).then((result) => {
          // if(result.error){
          //     // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
          //     console.log("User has closed the popup or there is some payment error, Check for Payment Status");
          //     console.log(result.error);
          // }
          // if(result.redirect){
          //     // This will be true when the payment redirection page couldnt be opened in the same window
          //     // This is an exceptional case only when the page is opened inside an inAppBrowser
          //     // In this case the customer will be redirected to return url once payment is completed
          //     console.log("Payment will be redirected");
          // }
          // if(result.paymentDetails){
          //     // This will be called whenever the payment is completed irrespective of transaction status
          //     console.log("Payment has been completed, Check for Payment Status");
          //     console.log(result.paymentDetails.paymentMessage);
          // }
        });
      }
    });
  };

  return (
    <div className='section-orders'>
      <div className="orders-con">
        <h1 className="section-title">Orders</h1>
        {orders.length > 0 && orders.map((order, orderIndex) => {
          return (
            <div className="order" key={orderIndex}>
              <div className="order-head">
                <div className="order-date">
                  <span className="label">Date: </span>
                  <span className="value">
                    {new Date(order.order_date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </span>
                </div>
                {console.log(order.payment_mode) && order.payment_status !== 'processing' && order.payment_status !== 'processed' && order.payment_status !== 'expired' && order.order_status !== 'cancelled' && order.payment_mode !== 'cod' && (
                  <div className='payment-note'>
                    <div className="note">
                      <span className="message">Payment Failed</span>
                      <span className="sub-message">
                        Any deducted amount will be sent back to your account in a few hours.
                      </span>
                    </div>
                    <div className="cta">
                      <button className="btn" onClick={() => retryPayment(order.oid)}>Retry</button>
                    </div>
                  </div>
                )}
              </div>
              <div className="order-body">
                {order.order_items.map((item, itemIndex) => {
                  const product = products.find(product => product.pid === item.pid);
                  const color = product?.colors.find(color => color.name === item.color);
                  return (
                    <div className="order-product" key={itemIndex}>
                      <div className="product-image">
                        {color && (
                          <img
                            src={color.images[0]}
                            alt="product"
                            className="product-img"
                            onClick={() => viewProduct(item.pid)}
                          />
                        )}
                      </div>
                      <div className="product-details">
                        {product && (
                          <div className="product-name" onClick={() => viewProduct(item.pid)}>
                            {product.name}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="cta">
                <button className="btn" onClick={() => handleViewOrder(order.oid)}>View Details</button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Orders;
