import React, { useEffect } from 'react';
import './css/Policy.css';

const ShippingPolicy = () => {

  useEffect(() => {
    document.title = 'Shipping Policy - Aizen Styles';
    document.querySelector('meta[name="description"]').setAttribute("content", "At Aizen Styles, we understand the excitement of receiving your order promptly. Here is our shipping policy.");
  }, []);

  window.scrollTo(0, 0);
  const email = 'support@aizenstyles.in';

  const handleMailClick = (event) => {
    event.preventDefault();
    const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;

    window.open(gmailComposeUrl, '_blank');
  };
  return (
    <div className='shipping-policy policy'>
      <div className="section-head">
          <h1 className='title'>Shipping<br />Policy</h1>
          <p className='description'>At Aizen Styles, we understand the excitement of receiving your order promptly. Here is our shipping policy.</p>
      </div>
      <div className="delivery-timeframe rule">
        <div className="head">
          <div className='title'>Delivery Timeframe</div>
          <div className="line"></div>
          <div className="hashtag">#1</div>
        </div>
        <div className="body">
          <p>Customers can expect their orders to be delivered within 5 to 10 days from the date of order confirmation.</p>
        </div>
      </div>
      <div className="dispatch-notification rule">
        <div className="head">
          <div className='title'>Dispatch Notification</div>
          <div className="line"></div>
          <div className="hashtag">#2</div>
        </div>
        <div className="body">
          <p>Once your order is dispatched, you will receive a notification with the tracking details, allowing you to monitor the delivery progress.</p>
        </div>
      </div>
      <div className="shipping-costs rule">
        <div className="head">
          <div className='title'>Shipping Costs</div>
          <div className="line"></div>
          <div className="hashtag">#3</div>
        </div>
        <div className="body">
          <span className='sub-title'>Free Shipping (Prepaid Orders)</span>
          <p>Enjoy the convenience of free shipping when you choose the prepaid option during checkout.</p>
          <span className='sub-title'>Cash-on-Delivery (COD) Orders</span>
          <p>For cash-on-delivery orders, a shipping charge of Rs. 40 will be applied. This charge helps us cover the additional processing costs associated with COD services.</p>
          <span className='sub-title'>International Shipping</span>
          <p>While we appreciate our global community, currently, our shipping services are available exclusively within PAN India. We hope to expand our reach in the future to serve customers beyond these borders.</p>
        </div>
      </div>
      <div className="contact rule">
        <div className="head">
          <div className='title'>Contact</div>
          <div className="line"></div>
          <div className="hashtag">#4</div>
        </div>
        <div className="body">
          <p>For any inquiries or assistance related to shipping, please feel free to contact our customer service team at <a onClick={handleMailClick}>{email}</a> or call us at <a href="tel:+919311852353">+919311852353</a>.</p>
        </div>
      </div>
    </div>
  )
}

export default ShippingPolicy