import React, { useEffect } from 'react'
import './css/Policy.css'
import { Link } from 'react-router-dom'

const ReturnPolicy = () => {

  useEffect(() => {
    document.title = 'Return Policy - Aizen Styles';
    document.querySelector('meta[name="description"]').setAttribute("content", "At Aizen Styles, we strive to ensure your satisfaction with every purchase. Here is our return policy.");
  }, []);

  window.scrollTo(0, 0);
  const email = 'support@aizenstyles.in';

  const handleMailClick = (event) => {
    event.preventDefault();
    const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;

    window.open(gmailComposeUrl, '_blank');
  };
  return (
    <div className='return-policy policy'>
      <div className="section-head">
        <h1 className='title'>Return<br />Policy</h1>
      </div>
      <div className="eligibility rule">
        <div className="head">
          <div className='title'>Eligibility for Returns</div>
          <div className="line"></div>
          <div className="hashtag">#1</div>
        </div>
        <div className="body">
          <span className='sub-title'>Time Frame</span>
          <p>Returns must be initiated within 5 days from the date of delivery.</p>
          <span className='sub-title'>Conditions for Return</span>
          <p>Returns are accepted only for products with defects from our side such as wrong size, wrong color, low print quality, or any other defects in the apparel. To be eligible for a return, customers must provide a video taken during the opening of the package that clearly shows the defect.</p>
        </div>
      </div>
      <div className="condition rule">
        <div className="head">
          <div className='title'>Condition of Returned Items</div>
          <div className="line"></div>
          <div className="hashtag">#2</div>
        </div>
        <div className="body">
          <span className='sub-title'>Unused and Original Condition</span>
          <p>The product must be unused and in the same condition as when you received it.</p>
          <span className='sub-title'>Original Packaging</span>
          <p>The product must be in its original packaging, along with all accessories, related products, and any bills or invoices that came with it.</p>
        </div>
      </div>
      <div className="proof rule">
        <div className="head">
          <div className='title'>Proof of Purchase</div>
          <div className="line"></div>
          <div className="hashtag">#3</div>
        </div>
        <div className="body">
          <span className='sub-title'>Order Number</span>
          <p>The order number is essential for verifying the purchase.</p>
          <span className='sub-title'>Receipts and Invoices</span>
          <p>If available, please include receipts and invoices to facilitate the return process.</p>
        </div>
      </div>
      <div className="process rule">
        <div className="head">
          <div className='title'>Return Process</div>
          <div className="line"></div>
          <div className="hashtag">#4</div>
        </div>
        <div className="body">
          <span className='sub-title'>Contact Customer Service</span>
          <p>Call us at <Link to="tel:+919311852353">+919311852353</Link> or email us at <Link onClick={handleMailClick}>aizen.renex@gmail.com</Link>. Our team will guide you through the return process.</p>
          <span className='sub-title'>Return Shipping</span>
          <p>We will arrange the return shipping from our end. Customers do not need to worry about anything.</p>
        </div>
      </div>
      <div className="shipping-cost rule">
        <div className="head">
          <div className='title'>Return Shipping Costs</div>
          <div className="line"></div>
          <div className="hashtag">#5</div>
        </div>
        <div className="body">
          <span className='sub-title'>Shipping Costs</span>
          <p>We are responsible for all return shipping costs.</p>
        </div>
      </div>
      <div className="refund rule">
        <div className="head">
          <div className='title'>Refunds and Exchanges</div>
          <div className="line"></div>
          <div className="hashtag">#6</div>
        </div>
        <div className="body">
          <span className='sub-title'>Refunds</span>
          <p>Refunds will be issued to the customer's bank account within 1-2 working days after the returned item is received and inspected.</p>
          <span className='sub-title'>Exchanges</span>
          <p>If you prefer an exchange, the new item will be sent within 5-7 days after the returned item is received and inspected.</p>
        </div>
      </div>
      <div className="damaged rule">
        <div className="head">
          <div className='title'>Damaged or Defective Items</div>
          <div className="line"></div>
          <div className="hashtag">#7</div>
        </div>
        <div className="body">
          <span className='sub-title'>Report Within 5 Days</span>
          <p>Contact us within 5 days of product delivery.</p>
          <span className='sub-title'>Video Proof Required</span>
          <p>To approve the return or refund, a video taken during the opening of the package that shows the defect is required. The video must be continuous, without any edits or cuts.</p>
        </div>
      </div>
      <div className="non-returnable rule">
        <div className="head">
          <div className='title'>Non-returnable Items</div>
          <div className="line"></div>
          <div className="hashtag">#8</div>
        </div>
        <div className="body">
          <p>Currently, there are no items that cannot be returned under the specified conditions.</p>
        </div>
      </div>
      <div className="contact rule">
        <div className="head">
          <div className='title'>Contact Information</div>
          <div className="line"></div>
          <div className="hashtag">#9</div>
        </div>
        <div className="body">
          <span><b>Phone: </b><Link to="tel:+919311852353">+919311852353</Link></span>
          <span><b>Email: </b><Link onClick={handleMailClick}>{email}</Link></span>
        </div>
      </div>
      <div className="additional rule">
        <div className="head">
          <div className='title'>Additional Notes</div>
          <div className="line"></div>
          <div className="hashtag">#10</div>
        </div>
        <div className="body">
          <span className='sub-title'>Video Proof Requirement</span>
          <p>For any defect or damage claims, a continuous video taken during the package opening that clearly shows the defect is required. This video will serve as proof for inspection and approval of the return, refund, or exchange. Without this video proof, there is a lower likelihood of the claim being approved.</p>
          <p>Thank you for shopping with Aizen Styles. We value your satisfaction and strive to provide the best possible service.</p>
        </div>
      </div>
    </div>
  )
}

export default ReturnPolicy
