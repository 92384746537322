import React, { useEffect } from 'react'

const PrivacyPolicy = () => {
  
  useEffect(() => {
    document.title = 'Privacy Policy - Aizen Styles';
    document.querySelector('meta[name="description"]').setAttribute("content", "At Aizen Styles, we value your privacy. Our privacy policy outlines how we collect, use, and protect your personal information.");
  }, []);
  
  window.scrollTo(0, 0);
  const email = 'support@aizenstyles.in';

  const handleMailClick = (event) => {
    event.preventDefault();
    const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;

    window.open(gmailComposeUrl, '_blank');
  };
  return (
    <div className='privacy-policy policy'>
      <div className="section-head">
          <h1 className='title'>Privacy<br />Policy</h1>
          <p className='description'>At Aizen Styles, customer satisfaction is our priority. To be eligible for a refund, the following conditions must be met.</p>
      </div>
      <div className="data-collection rule">
        <div className="head">
          <div className='title'>Data Collection</div>
          <div className="line"></div>
          <div className="hashtag">#1</div>
        </div>
        <div className="body">
          <span className='sub-title'>User-Entered Information</span>
          <p>Information provided by users during the registration process is securely stored to create and manage accounts.</p>
          <span className='sub-title'>Browsing Information</span>
          <p>We may collect data related to your browsing activities on our website to enhance user experience and improve our services.</p>
          <span className='sub-title'>Product Orders</span>
          <p>Information provided during the product ordering process, such as shipping details, is collected to fulfill orders efficiently.</p>
        </div>
        </div>
        <div className="information-usage rule">
          <div className="head">
            <div className='title'>Information Usage</div>
            <div className="line"></div>
            <div className="hashtag">#2</div>
          </div>
          <div className="body">
            <span className='sub-title'>Enhancing E-commerce Services</span>
            <p>We use customer data to continually improve and personalize our e-commerce services, making your shopping experience seamless and enjoyable.</p>
            <span className='sub-title'>Quality Product Offerings</span>
            <p>The information you provide helps us curate and present higher quality products that align with your preferences and expectations.</p>
            <span className='sub-title'>Third-Party Sharing</span>
            <p>At Aizen Styles, we prioritize your privacy and ensure that your information is treated confidentially.</p>
            <span className='sub-title'>No Third-Party Sharing</span>
            <p>Customer information, including personal details and browsing behavior, is not shared with any third parties. We are committed to maintaining the trust you place in us by using our website, and we take all necessary measures to secure your data.</p>
          </div>
        </div>
        <div className="contact rule">
          <div className="head">
            <div className='title'>Contact</div>
            <div className="line"></div>
            <div className="hashtag">#3</div>
          </div>
          <div className="body">
            <p>For further details on our privacy practices or if you have any questions, please contact us at <a onClick={handleMailClick}>{email}</a>.</p>
            <p>Thank you for choosing Aizen Styles!</p>
          </div>
        </div>
    </div>
  )
}

export default PrivacyPolicy
