import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

import whatsapp from '../Assets/icons/whatsapp.png'
import instagram from '../Assets/icons/instagram.png'

const Footer = () => {
return (
    <div className='footer'>
        <div className="footer-main">
            <div className="footer-nav">
                <div className="nav-menu help-info">
                    <h3>Help & Information</h3>
                    <ul>
                        <li className="nav-item"><Link to="/policy/shipping-policy">Shipping Policy</Link></li>
                        <li className="nav-item"><Link to="/policy/refund-and-cancellation">Refund and Cancellation</Link></li>
                        <li className="nav-item"><Link to="/policy/privacy-policy">Privacy Policy</Link></li>
                        <li className="nav-item"><Link to="/policy/return-policy">Return Policy</Link></li>
                        <li className="nav-item"><Link to="/policy/terms-and-conditions">Terms and Conditions</Link></li>
                    </ul>
                </div>
                <div className="nav-menu shopping" hidden>
                    <h3>Shopping</h3>
                    <ul>
                        <li className="nav-item"><Link to="/anime">Anime</Link></li>
                        <li className="nav-item"><Link to="/men">Men</Link></li>
                        <li className="nav-item"><Link to="/women">Women</Link></li>
                    </ul>
                </div>
            </div>
            <div className="more-info">
                <div className="socials">
                    <h3>Socials</h3>
                    <div className="social-item">
                        <Link to="https://wa.me/message/PQRPAPU4JDKWP1" target='_blank'>
                            <img src={whatsapp} alt="" />
                        </Link>
                    </div>
                    <div className="social-item">
                        <Link to="https://www.instagram.com/aizen.styles/" target='_blank'>
                            <img src={instagram} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="contact">
                    {/* <span><Link to="tel:+919311852353"><i className="bi bi-telephone"></i>+91 9311852353</Link></span> */}
                    <span><Link to="https://mail.google.com/mail/?view=cm&fs=1&to=support@aizenstyles.in&tf=1" target='_blank'><i className="bi bi-envelope"></i> support@aizenstyles.in</Link></span>
                </div>
            </div>
        </div>
        <div className="copyright">
            <span>&copy; {new Date().getFullYear()} Aizen. All Rights Reserved</span>
        </div>
    </div>
)
}

export default Footer
