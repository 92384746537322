import React from 'react'
import './Item.css'

import { Link } from 'react-router-dom'

const Item = (props) => {
  return (
    <div className='item'>
        <div className="item-img">
            <Link to={`/product/${props.pid}`}>
                <img src={props.colors[0].images[0]} alt="" />
            </Link>
        </div>
        <div className="item-details">
            <div className="item-info">
                <Link to={`/product/${props.pid}`}>
                    <span className='item-name'>{props.name}</span>
                    <div className="item-price">
                        <span className='sp'>&#8377; {props.colors[0].sizes[0].sellingPrice} </span>
                        <span className='mrp'>&#8377; {props.colors[0].sizes[0].mrp} </span>
                    </div>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Item
