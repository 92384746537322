import React, { useEffect, useState } from 'react';
import './css/ResetPassword.css';

const ResetPassword = () => {
    // get the token from the url
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [message, setMessage] = useState('');
    const [customer, setCustomer] = useState(null);
    const [isValidToken, setIsValidToken] = useState(false);

    useEffect(() => {
        // check if token is valid
        fetch(`${api_url}/verifyresetpasswordtoken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
            body: JSON.stringify({ token }),
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.success) {
                setIsValidToken(true);
            } else {
                setMessage(data.message);
            }
        })
        .catch((err) => {
            setMessage('An error occurred. Please try again.');
        }
        );
    }, [token]);

    useEffect(() => {
        document.title = 'Forgot Password - Aizen Styles';
        document.querySelector('meta[name="description"]').setAttribute("content", "Reset your Aizen Styles account password.");
    }, []);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const password = document.getElementById('password').value;
        const confirmPassword = document.getElementById('confirm-password').value;
        if (password === confirmPassword) {
            // send reset password request
            fetch(`${api_url}/resetpassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ token, password }),
            })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    document.querySelector('.reset-password-form .form').classList.add('hide');
                    document.querySelector('.confirmation').classList.remove('hide');
                    // wait 5 sec and then redirect to login page
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 5000);
                } else {
                    setMessage('An error occurred. Please try again.');
                }
            })
            .catch((err) => {
                setMessage('An error occurred. Please try again.');
            });
        } else {
            setMessage('Passwords do not match. Please try again.');
        }
    }

  return (
    <div className='section-reset-password'>
        {isValidToken ? (
            <div className="reset-password-form">
                <h1 className='section-title'>Reset Password</h1>
                <div className="message hide">
                    <p>{message}</p>
                </div>
                <div action="" className="form">
                    <div className="form-group">
                        <label htmlFor="password">New Password</label>
                        <input type="password" name="password" id="password" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirm-password">Confirm Password</label>
                        <input type="password" name="confirm-password" id="confirm-password" required />
                    </div>
                    <div className="form-group">
                        <button className='btn' type="submit" onClick={handleResetPassword}>Reset Password</button>
                    </div>
                </div>
                <div className="confirmation hide">
                    <p>Your password has been reset. You can now login with your new password.</p>
                </div>
            </div>
        ) : (
            <div className="expired-token">
                <div className="message">
                    <p>{message}</p>
                </div>
            </div>
        )}
    </div>
  )
}

export default ResetPassword
