import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  // const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('cartItems');
    console.log("logout", localStorage.getItem('auth-token'));
    // Redirect to login page and reload
    // navigate('/login');
    window.location = '/login';
  }, []);

  return null;
};

export default Logout;