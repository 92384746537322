import React, { useState, useEffect } from 'react'
import './css/Checkout.css'
import { Link } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import {load} from '@cashfreepayments/cashfree-js'
import { SHA256 } from 'crypto-js';

const Checkout = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;
    const frontend_url = process.env.REACT_APP_FRONTEND_URL;
    const cashfree_mode = process.env.REACT_APP_CASHFREE_MODE;
    const cashfree_redirect_target = process.env.REACT_APP_CASHFREE_REDIRECT_TARGET;

    const [step, setStep] = useState(1);
    const { isAuthenticated, token, cid } = useAuth();
    const [customerData, setCustomerData] = useState({});
    const [addressChange, setAddressChange] = useState(false);
    const [address, setAddress] = useState({first_name: '', last_name: '', phone: '', pincode: '', building: '', area: '', landmark: '', city: '', state: '', country: '', default: true});
    const [selectedAddress, setSelectedAddress] = useState({first_name: '', last_name: '', phone: '', pincode: '', building: '', area: '', landmark: '', city: '', state: '', country: '', default: true});
    const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []);
    const [productNames, setProductNames] = useState([]);
    const [productPrices, setProductPrices] = useState([]);
    const [totalMRP, setTotalMRP] = useState(0);
    const [totalSellingPrice, setTotalSellingPrice] = useState(0);
    
    const [paymentMethod, setPaymentMethod] = useState('');
    const [shippingCharges, setShippingCharges] = useState(0);
    
    const [coupons, setCoupons] = useState([]);
    const [couponApplied, setCouponApplied] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [discountCoupon, setDiscountCoupon] = useState({});
    const [discount, setDiscount] = useState(0);
    const [orderTotal, setOrderTotal] = useState(0);

    useEffect(() => {
        if (!localStorage.getItem('checkout')){
            window.location.href = '/orders';
        }
        fetch(`${api_url}/allcoupons`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
        })
        .then(res => res.json())
        .then(data => {
            setCoupons(data);
        });
    }, []);

    useEffect(() => {
        setOrderTotal(totalSellingPrice + shippingCharges - discount);
    }, [totalSellingPrice, shippingCharges, discount]);

    useEffect(() => {
        if (cartItems && cartItems.length > 0) {
            let names = [];
            let prices = [];
            let totalmrp = 0;
            let totalselling = 0;

            cartItems.map((item) => {
                fetch(`${api_url}/getproductbypid`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': api_key,
                        'x-client-id': client_id,
                    },
                    body: JSON.stringify({ pid: item.pid }),
                })
                .then(res => res.json())
                .then(data => {
                    if(data.success) {
                        names.push(data.product.name);
                        data.product.colors.map((color) => {
                            if (color.name === item.color.name) {
                                color.sizes.map((size) => {
                                    if (size.size === item.size) {
                                        prices.push({size: size.size, color: color.name, mrp: size.mrp, sellingPrice: size.sellingPrice, quantity: item.quantity});
                                        totalmrp += size.mrp * item.quantity;
                                        totalselling += size.sellingPrice * item.quantity;
                                    }
                                });
                            }
                        });
                        setTotalMRP(totalmrp);
                        setTotalSellingPrice(totalselling);
                    }
                });
            });
            setProductNames(names);
            setProductPrices(prices);
        }
    }, [cartItems]);

    const handleBack = () => {
        setStep(step - 1);
        window.scrollTo(0, 0);
    }
    const handleNext = () => {
        if (!isAuthenticated){
            window.location.href = '/login';
        }
        else{
            if (step === 1){
                // if all the fields are filled in selectedAddress
                if (selectedAddress.first_name && selectedAddress.last_name && selectedAddress.phone && selectedAddress.pincode && selectedAddress.building && selectedAddress.area && selectedAddress.landmark && selectedAddress.city && selectedAddress.state && selectedAddress.country){
                    setStep(step + 1);
                    window.scrollTo(0, 0);
                }
                else{
                    alert('Please select an address');
                }
            }
            if (step === 2){
                if (paymentMethod === ''){
                    alert('Please select a payment method');
                    return;
                }
                setStep(step + 1);
                window.scrollTo(0, 0);
            }
        }
    }

    const changeHandler = (e) => {
        if(e.target.name === 'default') {
            setAddress({...address, [e.target.name]: e.target.checked});
            return;
        }
        setAddress({...address, [e.target.name]: e.target.value});
    }

    const changeAddressHandler = (e) => {
        const index = e.target.id.split('-')[1];
        let temp = customerData.addresses[index-1];
        temp.address_label = index; 
        setSelectedAddress(temp);
    }

    const handleSaveAddress = () => {
        // check if all fields are filled
        if (address.first_name && address.last_name && address.phone && address.pincode && address.building && address.area && address.landmark && address.city && address.state && address.country){
            setCustomerData({...customerData, addresses: [...customerData.addresses, address]});
            setAddressChange(true);
        }
        else{
            alert('Please fill all the fields');
        }
    }

    const handlePaymentMethodChange = (e) => {
        if (paymentMethod === 'cod'){
            setOrderTotal(orderTotal - 40);
        }
        setPaymentMethod(e.target.value);
        if (e.target.value === 'cod'){
            setShippingCharges(40);
            setOrderTotal(orderTotal + 40);
        }
        else{
            setShippingCharges(0);
        }
    }

    useEffect(() => {
        if(addressChange){
            let customer = customerData;
            fetch(`${api_url}/updatecustomer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ cid: cid, customer: customer }),
            })
            .then(res => res.json())
            .then(data => {
            });
        }
    },[addressChange]);

    useEffect(() => {
        if (customerData.addresses){
            customerData.addresses.map((address, index) => {
                if(address.default) {
                    address.address_label = index+1;
                    setSelectedAddress(address);
                    if (step === 1){
                        const radioBtn = document.getElementById(`address-${index+1}`);
                        radioBtn.checked = true;
                    }
                    return;
                }
            });
        }

    }, [customerData]);

    useEffect(() => {
        if (step === 1){
            if (selectedAddress.address_label){
                const radioBtn = document.getElementById(`address-${selectedAddress.address_label}`);
                radioBtn.checked = true;
            }
        }
    }, [step]);

    const handleUseCoupon = (coupon) => {
        setCouponApplied(true);
        setDiscountCoupon(coupon);
        setDiscountCode(coupon.code);
        if (coupon.type === 'percentage'){
            setDiscount((coupon.discount * totalSellingPrice) / 100);
        }
        if (coupon.type === 'flat'){
            setDiscount(coupon.discount);
        }
    }

    const handleRemoveCoupon = (coupon) => {
        setCouponApplied(false);
        setDiscountCoupon({});
        setDiscountCode('');
        setDiscount(0);
    }

    const applyCoupon = (code) => {
        let codes = [];
        coupons.map((coupon) => {
            codes.push(coupon.code);
        });

        if (code === ''){
            return;
        }
        if (!codes.includes(code)){
            alert('Please enter a valid coupon code');
            return;
        }
        if (couponApplied && discountCode === code){
            return;
        }
        coupons.map((coupon) => {
            if (coupon.code === code){
                if (totalSellingPrice >= coupon.min_order){
                    if (coupon.type === 'percentage'){
                        setDiscount((coupon.discount * totalSellingPrice) / 100);
                        setDiscountCoupon(coupon);
                        setDiscountCode(code);
                        setCouponApplied(true);
                    }
                    if (coupon.type === 'flat'){
                        setDiscount(coupon.discount);
                        setDiscountCoupon(coupon);
                        setDiscountCode(code);
                        setCouponApplied(true);
                    }
                }
                else{
                    alert('Minimum order amount not met, please add more items to cart');
                    setDiscount(0);
                    setDiscountCoupon({});
                    setDiscountCode('');
                    setCouponApplied(false);
                }
            }
        });
    }

    // get customer data using  cid
    useEffect(() => {
        if(isAuthenticated){
            fetch(`${api_url}/getcustomer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ cid: cid }),
            })
            .then(res => res.json())
            .then(data => {
                if(data.success) {
                    setCustomerData(data.customer);
                }
            })
        }
    }, [isAuthenticated, cid]);


    const generateOrderId = (cid) => {
        const timestamp = Date.now(); // Current timestamp
        const data = `${cid}-${timestamp}`;
      
        // Hash the data using SHA-256
        const hash = SHA256(data).toString();
      
        // Take a substring of the hash to ensure the final order ID is short
        const orderId = hash.substring(0, 10);
      
        return orderId;
    }

    const handleMakePayment = async () => {
        if (isAuthenticated){
            if (paymentMethod === ''){
                alert('Please select a payment method');
                return;
            }

            let orderDetails = {};
            // let orderFinalTotal = orderTotal;
            let orderData = [];

            const cartItems = JSON.parse(localStorage.getItem('cartItems'));
            if (cartItems && cartItems.length > 0) {
                // prepare orderData
                orderData = cartItems.map((item) => ({
                    pid: item.pid,
                    color: item.color.name,
                    size: item.size,
                    quantity: item.quantity,
                }));
    
                // // Calculate order total
                // cartItems.map((item) => {
                //     item.color.sizes.map((size) => {
                //         if (size.size === item.size) {
                //             orderFinalTotal += size.sellingPrice * item.quantity;
                //         }
                //     });
                // });
            }
    
            // if (paymentMethod === 'cod') {
            //     orderFinalTotal += 40;
            // }

            // if (discountCoupon){
            //     if (discountCoupon.type === 'percentage'){
            //         orderFinalTotal -= (discountCoupon.discount * totalSellingPrice) / 100;
            //     }
            //     if (discountCoupon.type === 'flat'){
            //         orderFinalTotal -= discountCoupon.discount;
            //     }
            // }

            let order = {
                oid: generateOrderId(cid),
                cid: cid,
                order_items: orderData,
                order_total: orderTotal,
                shipping_address: selectedAddress,
                billing_address: selectedAddress,
                payment_mode: paymentMethod,
                shipping: shippingCharges,
                payment_status: 'pending',
                discount: discount,
                discount_code: discountCoupon.code,
                tracking_id: '',
                tracking_url: '',
                order_status: paymentMethod==='cod' ? 'confirmed' : 'pending',
            };
            orderDetails = order;
            
            localStorage.removeItem('checkout');
            localStorage.removeItem('cartItems');
            fetch(`${api_url}/clearcart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ cid: cid }),
            })
            .then(res => res.json())
            .then(data => {
            });
    
            // Load Cashfree UPI payment gateway
            const cashfree = await load({
                mode: cashfree_mode,
            });

            try {
                // Create order in backend
                console.log(api_key, client_id);
                const response = await fetch(`${api_url}/createorder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ orderDetails }),
                });
                const data = await response.json();
                if (!data.success) throw new Error('Order creation failed');        
                
                if (paymentMethod === 'cod'){
                    window.location.href = `/paymentconfirmation?orderId=${data.orderId}&paymentMode=${paymentMethod}`;
                    return;
                }
    
                console.log(cashfree_mode, cashfree_redirect_target);
                cashfree.checkout({
                    paymentSessionId: data.cashfreePaymentSessionId,
                    redirectTarget: cashfree_redirect_target,
                    returnUrl: `${frontend_url}/paymentconfirmation?orderId=${data.orderId}&paymentMode=${paymentMethod}`,
    
                }).then((result) => {
                    // if(result.error){
                    //     // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
                    //     console.log("User has closed the popup or there is some payment error, Check for Payment Status");
                    //     console.log(result.error);
                    // }
                    // if(result.redirect){
                    //     // This will be true when the payment redirection page couldnt be opened in the same window
                    //     // This is an exceptional case only when the page is opened inside an inAppBrowser
                    //     // In this case the customer will be redirected to return url once payment is completed
                    //     console.log("Payment will be redirected");
                    // }
                    // if(result.paymentDetails){
                    //     // This will be called whenever the payment is completed irrespective of transaction status
                    //     console.log("Payment has been completed, Check for Payment Status");
                    //     console.log(result.paymentDetails.paymentMessage);
                    // }
                });
            }
            catch (error) {
                // console.error(error);
            }
        }
        else{
            window.location.href = '/login';
        }
          
    };

  return (
    <div className='checkout'>
        <div className="section-head">
            <h1 className='section-title'>Checkout</h1>
        </div>

        {step === 1 && (
            <div className="main" id={`checkout-${step}`}>
                <div className="progress">
                    <div className="step-title step1 active">
                        <span>01&nbsp;</span>
                        <span>Shipping</span>
                    </div>
                    <div className="step-title step2">
                        <span>02&nbsp;</span>
                        <span>Payment Method</span>
                    </div>
                    <div className="step-title step3">
                        <span>03&nbsp;</span>
                        <span>Summary</span>
                    </div>
                </div>

                <div className="address-con">
                    {customerData.addresses && customerData.addresses.length > 0 ? (
                    <h3>Choose your delivery address:</h3>
                    ):(null)}
                    {customerData.addresses && customerData.addresses.length > 0 ? ( customerData.addresses.map((address, index) => (
                    <div key={index} className="address" id={`address-card-${index+1}`}>
                        <div className="address-select">
                            <input type="radio" name="address" id={`address-${index+1}`} className='radioBtn' onChange={(e)=>changeAddressHandler(e)}/>
                        </div>
                        <div className="address-detail">
                            <div className="address-card-header">
                                <div className="address-count">Address {index + 1}</div>
                                <div className="fullname">{address.first_name} {address.last_name}</div>
                                <div className="phone">{address.phone}</div>
                            </div>
                            <div className="address-card-address">
                                <div className="building">{address.building}</div>
                                <div className="area">{address.area}</div>
                                <div className="landmark">{address.landmark}</div>
                                <div className="pincode">{address.pincode}</div>
                                <div className="city">{address.city}</div>
                                <div className="state">{address.state}</div>
                                <div className="country">{address.country}</div>
                            </div>
                        </div>
                    </div>
                    ))):(null)}
                    {customerData.addresses && customerData.addresses.length > 0 ? (
                    <button className="step-btn next btn cta" onClick={handleNext}>Continue</button>
                    ):(null)}
                </div>
                
                {customerData.addresses && customerData.addresses.length == 0 ? (
                <div className="form">
                    <div className="form-group name form-group-row">
                        <div className="firstname">
                            <label htmlFor="firstname">First Name</label>
                            <input type="text" value={address.first_name} name="first_name" id="firstname" onChange={(e)=>changeHandler(e)} placeholder='First Name'/>
                        </div>
                        <div className="lastname">
                            <label htmlFor="lastname">Last Name</label>
                            <input type="text" value={address.last_name} name="last_name" id="lastname" onChange={(e)=>changeHandler(e)} placeholder='Last Name'/>
                        </div>
                    </div>
                    <div className="form-group phone">
                        <label htmlFor="phone">Phone</label>
                        <input type="text" value={address.phone} name="phone" id="phone" onChange={(e)=>changeHandler(e)} placeholder='Phone'/>
                    </div>
                    <div className="form-group building">
                        <label htmlFor="building">Building / House No.</label>
                        <input type="text" value={address.building} name="building" id="building" onChange={(e)=>changeHandler(e)} placeholder='Building / House No.'/>
                    </div>
                    <div className="form-group area">
                        <label htmlFor="area">Area</label>
                        <input type="text" value={address.area} name="area" id="area" onChange={(e)=>changeHandler(e)} placeholder='Area'/>
                    </div>
                    <div className="form-group landmark">
                        <label htmlFor="landmark">Landmark</label>
                        <input type="text" value={address.landmark} name="landmark" id="landmark" onChange={(e)=>changeHandler(e)} placeholder='Landmark'/>
                    </div>
                    <div className="form-group pincode">
                        <label htmlFor="pincode">Pincode</label>
                        <input type="text" value={address.pincode} name="pincode" id="pincode" onChange={(e)=>changeHandler(e)} placeholder='Pincode'/>
                    </div>
                    <div className="form-group city">
                        <label htmlFor="city">City</label>
                        <input type="text" value={address.city} name="city" id="city" onChange={(e)=>changeHandler(e)} placeholder='City'/>
                    </div>
                    <div className="form-group state">
                        <label htmlFor="state">State</label>
                        <input type="text" value={address.state} name="state" id="state" onChange={(e)=>changeHandler(e)} placeholder='State'/>
                    </div>
                    <div className="form-group country">
                        <label htmlFor="country">Country</label>
                        <input type="text" value={address.country} name="country" id="country" onChange={(e)=>changeHandler(e)} placeholder='Country'/>
                    </div>
                    <div className="form-group default">
                        <input type="checkbox" checked={address.default} name="default" id="default" onChange={(e)=>changeHandler(e)} />
                        <label htmlFor="default">Make this my default address</label>
                    </div>
                    <button className="btn save" onClick={handleSaveAddress}>Use Address</button>
                </div>
                ):(null)}
            </div>
        )}
        {step === 2 && (
            <div className="main" id={`checkout-${step}`}>
                <div className="progress">
                    <div className="step-title step1">
                        <span>01&nbsp;</span>
                        <span>Shipping</span>
                    </div>
                    <div className="step-title step2 active">
                        <span>02&nbsp;</span>
                        <span>Payment Method</span>
                    </div>
                    <div className="step-title step3">
                        <span>03&nbsp;</span>
                        <span>Summary</span>
                    </div>
                </div>
                <div className="main-content">
                    <div className="left">
                        <div className="payment-options">
                            <h3>Select a payment method:</h3>
                            <div className="payment-methods">
                                <div className="payment-method">
                                    <input
                                        type="radio"
                                        name="payment"
                                        id="payment-1"
                                        className='radioBtn'
                                        value="cc,dc"
                                        checked={paymentMethod === "cc,dc"}
                                        onChange={handlePaymentMethodChange}
                                    />
                                    <label htmlFor="payment-1">Credit / Debit Card</label>
                                </div>
                                <div className="payment-method">
                                    <input
                                        type="radio"
                                        name="payment"
                                        id="payment-2"
                                        className='radioBtn'
                                        value="nb"
                                        checked={paymentMethod === "nb"}
                                        onChange={handlePaymentMethodChange}
                                    />
                                    <label htmlFor="payment-2">Net Banking</label>
                                </div>
                                <div className="payment-method">
                                    <input
                                        type="radio"
                                        name="payment"
                                        id="payment-3"
                                        className='radioBtn'
                                        value="upi"
                                        checked={paymentMethod === "upi"}
                                        onChange={handlePaymentMethodChange}
                                    />
                                    <label htmlFor="payment-3">UPI</label>
                                </div>
                                <div className="payment-method">
                                    <input
                                        type="radio"
                                        name="payment"
                                        id="payment-4"
                                        className='radioBtn'
                                        value="app"
                                        checked={paymentMethod === "app"}
                                        onChange={handlePaymentMethodChange}
                                    />
                                    <label htmlFor="payment-4">Wallets</label>
                                </div>
                                {/* <div className="payment-method">
                                    <input
                                        type="radio"
                                        name="payment"
                                        id="payment-5"
                                        className='radioBtn'
                                        value="paylater"
                                        checked={paymentMethod === "paylater"}
                                        onChange={handlePaymentMethodChange}
                                    />
                                    <label htmlFor="payment-5">Pay Later</label>
                                </div> */}
                                <div className="payment-method">
                                    <input
                                        type="radio"
                                        name="payment"
                                        id="payment-6"
                                        className='radioBtn'
                                        value="cod"
                                        checked={paymentMethod === "cod"}
                                        onChange={handlePaymentMethodChange}
                                    />
                                    <label htmlFor="payment-6">Cash on Delivery</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <h3>Order Summary</h3>
                        <div className="order-summary">
                            <div className="item-total row subtotal">
                                <span className="item-total-label label">Subtotal</span>
                                <span className="item-total-price value">Rs. {totalSellingPrice}</span>
                            </div>
                            <div className="shipping row">
                                <span className="shipping-label label">Shipping</span>
                                <span className="shipping-price value">Rs. {shippingCharges}</span>
                            </div>
                            {discount > 0 && (
                            <div className="discount row">
                                <span className="discount-label label">Your Savings</span>
                                <span className="discount-price value">Rs. {discount}</span>
                            </div>
                            )}
                            <div className="coupon-window">
                                <div className="coupon">
                                    <input type="text" name="coupon" id="coupon" value={discountCode} onChange={(e) => setDiscountCode(e.target.value)} placeholder='Apply a coupon'/>
                                    <button className="btn apply" onClick={() => applyCoupon(discountCode.toUpperCase())}>Apply</button>
                                </div>
                                <div className="coupon-list">
                                    {coupons && coupons.length > 0 && coupons.map((coupon, index) => (
                                        coupon.visibility ? (
                                        <div className={`coupon-item ${couponApplied && discountCode === coupon.code ? 'active' : ''}`} key={index}>
                                            <div className="coupon-details">
                                                <div className="name">{coupon.code}</div>
                                                {couponApplied && discountCoupon.code === coupon.code ? (
                                                    <div className="cta" onClick={()=>handleRemoveCoupon(coupon)}>Remove</div>
                                                )
                                                : (
                                                    <div className="cta" onClick={()=>handleUseCoupon(coupon)}>Apply</div>
                                                )
                                            }
                                            </div>
                                            <div className="coupon-desc">{coupon.description}</div>
                                        </div>
                                        ) : null
                                    ))}
                                </div>
                            </div>
                            <div className="order-total row total">
                                <span className="order-total-label label">Total</span>
                                <span className="order-total-price value">Rs. {orderTotal}</span>
                            </div>
                            <div className="tnc-note">
                                <p>
                                By continuing, I confirm that i have read and accept the <Link to='/policy/terms-and-conditions'>Terms and Conditions</Link> and <Link to='/policy/privacy-policy'>Privacy Policy</Link>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-cta">
                    <button className="step-btn back btn cta" onClick={handleBack}>Back</button>
                    <button className="step-btn next btn cta" onClick={handleNext}>Next</button>
                </div>
            </div>
        )}
        {step === 3 && (
            <div className="main" id={`checkout-${step}`}>
                <div className="progress">
                    <div className="step-title step1">
                        <span>01&nbsp;</span>
                        <span>Shipping</span>
                    </div>
                    <div className="step-title step2">
                        <span>02&nbsp;</span>
                        <span>Payment Method</span>
                    </div>
                    <div className="step-title step3 active">
                        <span>03&nbsp;</span>
                        <span>Summary</span>
                    </div>
                </div>
                <div className="main-content">
                    <div className="left">
                        <div className="order-items">
                            {cartItems != [] && cartItems.map((item, index) => (
                            <div className="item">
                                <div className="item-image">
                                    <img src={item.color.images[0]} alt="product" />
                                </div>
                                <div className="item-details">
                                    <div className="details-head">
                                        <div className="item-name">{productNames[index]}</div>
                                        {productPrices.map((price) => (
                                            price.size === item.size && price.color === item.color.name && (
                                            <div className="item-price">Rs. {price.sellingPrice * price.quantity}</div>
                                            )
                                        ))}
                                    </div>
                                    <div className="details-body">
                                        <div className="item-color">
                                            <span className='label'>Color: </span>
                                            <span>{item.color.name}</span>
                                        </div>
                                        <div className="item-size">
                                            <span className='label'>Size: </span>
                                            <span>{item.size}</span>
                                        </div>
                                        <div className="item-quantity">
                                            <span className='label'>Qty: </span>
                                            <span>{item.quantity}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                        <div className="shipping-summary">
                            <h3>Shipping Address</h3>
                            <div className="shipping-address">
                                <div className="address-card-header">
                                    <div className="fullname">{selectedAddress.first_name} {selectedAddress.last_name}</div>
                                    <div className="phone">{selectedAddress.phone}</div>
                                </div>
                                <div className="address-card-address">
                                    <div className="building">{selectedAddress.building}</div>
                                    <div className="area">{selectedAddress.area}</div>
                                    <div className="landmark">{selectedAddress.landmark}</div>
                                    <div className="pincode">{selectedAddress.pincode}</div>
                                    <div className="city">{selectedAddress.city}</div>
                                    <div className="state">{selectedAddress.state}</div>
                                    <div className="country">{selectedAddress.country}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <h3>Order Summary</h3>
                        <div className="order-summary">
                            <div className="item-total row subtotal">
                                <span className="item-total-label label">Subtotal</span>
                                <span className="item-total-price value">Rs. {totalSellingPrice}</span>
                            </div>
                            <div className="shipping row">
                                <span className="shipping-label label">Shipping</span>
                                <span className="shipping-price value">Rs. {shippingCharges}</span>
                            </div>
                            {discount > 0 && (
                            <div className="discount row">
                                <span className="discount-label label">Your Savings</span>
                                <span className="discount-price value">Rs. {discount}</span>
                            </div>
                            )}
                            <div className="order-total row total">
                                <span className="order-total-label label">Total</span>
                                <span className="order-total-price value">Rs. {orderTotal}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-cta">
                    <button className="step-btn back btn cta" onClick={handleBack}>Back</button>
                    <button className="step-btn next btn cta" onClick={handleMakePayment}>Proceed</button>
                </div>
            </div>
        )}

    </div>
  )
}

export default Checkout