import React, { useEffect, useState } from 'react'
import './OrderDetails.css'
import { Link, useParams } from 'react-router-dom'
import { load } from '@cashfreepayments/cashfree-js';


const OrderDetails = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;
    const frontend_url = process.env.REACT_APP_FRONTEND_URL;
    const cashfree_mode = process.env.REACT_APP_CASHFREE_MODE;
    const cashfreeRedirectTarget = process.env.REACT_APP_CASHFREE_REDIRECT_TARGET;
    
    const email = 'support@aizenstyles.in';

    const { orderId } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const [productDetails, setProductDetails] = useState([]);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [trackingData, setTrackingData] = useState(null);

    useEffect(() => {
        if (orderDetails && orderDetails.tracking_id != '' && orderDetails.tracking_id) {
            fetch(`${api_url}/gettrackingdata`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                // body: JSON.stringify({ tracking_id: "141123441013652", track_by: 'tracking_id'}),
                body: JSON.stringify({ tracking_id: orderDetails.tracking_id, track_by: 'tracking_id'}),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success){
                    setTrackingData(data.trackingData.tracking_data);
                }
            });
        }
    }, [orderDetails]);


    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        result = new Date(result).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
        return result;
    };

    const handleMailClick = (event) => {
        event.preventDefault();
        const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
    
        window.open(gmailComposeUrl, '_blank');
    };

    useEffect(() => {
        fetch(`${api_url}/getorder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
            body: JSON.stringify({ oid: orderId }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success){
                setOrderDetails(data.order);
            }
        });
    },[]);

    useEffect(() => {
        if (orderDetails!=null) {
            if (productDetails.length===0){
                orderDetails.order_items.map(product => {
                    fetch(`${api_url}/getproductbypid`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': api_key,
                            'x-client-id': client_id,
                        },
                        body: JSON.stringify({ pid: product.pid }),
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success){
                            setProductDetails([...productDetails, data.product]);
                            
                            fetch(`${api_url}/getcustomer`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'x-api-key': api_key,
                                    'x-client-id': client_id,
                                },
                                body: JSON.stringify({ cid: orderDetails.cid }),
                            })
                            .then(response => response.json())
                            .then(data => {
                                if (data.success){
                                    setCustomerDetails(data.customer);
                                }
                            })
                        }
                    });
                });
            }
    
        }
    }, [orderDetails]);

    const retryPayment = async (oid) => {
        const cashfree = await load({
          mode: cashfree_mode,
        });
    
        fetch(`${api_url}/verifyorder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key,
            'x-client-id': client_id,
          },
          body: JSON.stringify({ orderId: oid }),
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            cashfree.checkout({
              paymentSessionId: data.payment_session_id,
              redirectTarget: cashfreeRedirectTarget,
              returnUrl: `${frontend_url}/paymentconfirmation?orderId=${oid}&paymentMode=cc,dc,nb,upi`,
        
            }).then((result) => {
            //   if(result.error){
            //       // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
            //       console.log("User has closed the popup or there is some payment error, Check for Payment Status");
            //       console.log(result.error);
            //   }
            //   if(result.redirect){
            //       // This will be true when the payment redirection page couldnt be opened in the same window
            //       // This is an exceptional case only when the page is opened inside an inAppBrowser
            //       // In this case the customer will be redirected to return url once payment is completed
            //       console.log("Payment will be redirected");
            //   }
            //   if(result.paymentDetails){
            //       // This will be called whenever the payment is completed irrespective of transaction status
            //       console.log("Payment has been completed, Check for Payment Status");
            //       console.log(result.paymentDetails.paymentMessage);
            //   }
            });
          }
        });
      };

    const handleCancelOrder = (oid) => {
        fetch(`${api_url}/cancelorder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
            body: JSON.stringify({ oid }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                alert('Order Cancelled Successfully');
                window.location.reload();
            }
        });
    }

  return (
    <div className="order-details">
        {orderId && orderDetails && productDetails && customerDetails && (
        <>
        <div className="section-head">
            <div className="section-title">
                <h1 className='title'>Order Details</h1>
            </div>
            <div className="order-info">
                <div className="tracking-id">
                    <span className='label'>Tracking Id</span>
                    <span className='value'>#{orderDetails && orderDetails.tracking_id}</span>
                </div>
                <div className="order-id">
                    <span className='label'>Order Id</span>
                    <span className='value'>#{orderDetails && orderDetails.oid}</span>
                </div>
                <div className="order-date">
                    <span className='label'>Order Date</span>
                    <span className='value'>{orderDetails && new Date(orderDetails.order_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    })}</span>
                </div>
            </div>
        </div>
        <div className="main">
            <div className="left">
                <div className="customer-details">
                    <div className="customer-info customer-name">
                        <span className='label'>Customer Name</span>
                        <span className='value'>{customerDetails.first_name} {customerDetails.last_name}</span>
                    </div>
                    <div className="customer-info customer-phone">
                        <span className='label'>Customer Contact</span>
                        <span className='value'>{customerDetails.phone}</span>
                    </div>
                    <div className="customer-info shipping-address">
                        <span className='label'>Shipping Address</span>
                        {orderDetails && orderDetails.shipping_address && (
                        <span className='value'>
                            {orderDetails.shipping_address.building}, {orderDetails.shipping_address.area} <br /> {orderDetails.shipping_address.landmark && orderDetails.shipping_address.landmark != '' ? (
                                <>
                                <span>Landmark: </span><span>{orderDetails.shipping_address.landmark}</span><br />
                                </>
                            ) : (null)} {orderDetails.shipping_address.city}, {orderDetails.shipping_address.pincode}, {orderDetails.shipping_address.state}
                        </span>
                        )}
                    </div>
                </div>
                <div className="payment-info">
                    <div className="order-total">
                        <span className='label'>Order Total</span>
                        <span className='value'>₹ {orderDetails && orderDetails.order_total}</span>
                    </div>
                    <div className="payment-mode">
                        <span className='label'>Payment Mode</span>
                        <span className='value'>{orderDetails && orderDetails.payment_mode}</span>
                    </div>
                    <div className="payment-status">
                        <span className='label'>Payment Status</span>
                        <span className='value'>{orderDetails && orderDetails.payment_status}</span>
                    </div>
                    {orderDetails.payment_status !== 'processing' && orderDetails.payment_status !== 'processed' && orderDetails.payment_status !== 'expired' && orderDetails.order_status !== 'cancelled' && orderDetails.payment_mode !== 'cod' ?
                        <div className="btn pay" onClick={() => retryPayment(orderDetails.oid)}>Pay</div> : null
                    }
                </div>
            </div>
            <div className="right">
                <div className="top">
                    <div className="left">
                        <div className="order-status">
                            <span className='label'>Your order is</span>
                            <span className='value'>{orderDetails.order_status}</span>
                        </div>
                        <div className="order-items">
                            <h1 className='title'>Order Items</h1>
                            {orderDetails.order_items.map(orderItem => {
                                return productDetails.map(product => {
                                    if (product.pid === orderItem.pid) {
                                        return (
                                            <div className="item">
                                                <div className="item-image">
                                                    {product.colors && product.colors.map(color => {
                                                        if (color.name === orderItem.color) {
                                                            return <img src={color.images[0]} alt={product.name} />;
                                                        }
                                                        return null;
                                                    }
                                                    )}
                                                </div>
                                                <div className="item-details">
                                                    <div className="details-head">
                                                        <div className="item-name">{product.name}</div>
                                                    </div>
                                                    <div className="details-body">
                                                        <div className="item-color">
                                                            <span className='label'>Color: </span>
                                                            <span className='value'>{orderItem.color}</span>
                                                        </div>
                                                        <div className="item-size">
                                                            <span className='label'>Size: </span>
                                                            <span className='value'>{orderItem.size}</span>
                                                        </div>
                                                        <div className="item-quantity">
                                                            <span className='label'>Quantity: </span>
                                                            <span className='value'>{orderItem.quantity}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                });
                            })}
                        </div>
                    </div>
                    <div className="cta">
                        <div className="order-cta">
                            {
                                orderDetails.order_status !== 'delivered' ? 
                                <div className="btn return">Return Order</div> : null 
                            }
                            {
                                orderDetails.order_status === 'confirmed' || orderDetails.order_status==='pending' ?
                                <div className="btn cancel" onClick={()=>handleCancelOrder(orderDetails.oid)}>Cancel</div> : null
                            }
                            {
                                // orderDetails.order_status === 'cancelled' ? 
                                // <div className="btn reorder">Reorder</div> : null 
                            }
                        </div>
                        <div className="help-cta">
                            <span>Need Help?</span>
                            <div className="btn mail" onClick={handleMailClick}>Mail Us</div>
                            <div className="btn call">Call Us</div>
                        </div>
                    </div>
                </div>
                <div className="order-tracking">
                    {trackingData ? (
                        <>
                        {/* <div className="tracking-btn">
                            <span className='label'>Track Your Order</span>
                            <button className="btn track" onClick={() => window.open(trackingData.track_url, '_blank')}>Track Order</button>
                        </div> */}
                        <div className="order-timeline">
                            <div className="edd-info">
                                {trackingData.shipment_track[0].current_status === 'Delivered' ? (
                                <>
                                <span className='label'>Delivered On</span>
                                <span className='date value'>{new Date(trackingData.shipment_track[0].delivered_date).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}
                                </span>
                                </>
                                ) : (
                                <>
                                <span className='label'>Estimated Delivery</span>
                                <span className='date value'>{new Date(trackingData.shipment_track[0].edd).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}</span>
                                </>
                                )}
                            </div>
                            <div className="tracking-btn">
                                <Link to={`${trackingData.track_url}`} target="_blank">Track Order</Link>
                            </div>
                            <div className="timeline">
                                {trackingData && trackingData.shipment_track_activities.map((activity, index) => (
                                    <div className="activity row" key={index}>
                                        <div className="date-time">
                                            <span className="date">{new Date(activity.date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}</span>
                                            <span className="time">{new Date(activity.date).toLocaleTimeString('en-US', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            })}</span>
                                        </div>
                                        <div className="progress">
                                            <div className="line"></div>
                                            <div className={`circle ${index===0 ? 'active' : ''}`}></div>
                                        </div>
                                        <div className="activity-details">
                                            <span className="status">{activity.activity}</span>
                                            <span className="location">{activity.location}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        </>
                    ) : (
                        <div className="order-timeline">
                            <div className="edd-info">
                                {/* est delivery date using adddays */}
                                {orderDetails.date_delivered &&
                                <>
                                <span className='label'>Estimated Delivery</span>
                                <span className='date value'>
                                    {`${addDays(orderDetails.order_date, 7)}`}
                                </span>
                                </>
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
        )}
    </div>
  )
}

export default OrderDetails