import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './Header.css';

import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import { ShopContext } from '../../Context/ShopContext';
import useAuth from '../../hooks/useAuth';
import Navbar from '../Navbar/Navbar';

import logo from '../Assets/logo.png';
import cartBlack from '../Assets/icons/cart-black.png';
import userBlack from '../Assets/icons/user.png';
import cartWhite from '../Assets/icons/cart-white.png';
import userWhite from '../Assets/icons/user-white.png';

const Header = ({onSearchClick}) => {
  const api_url = process.env.REACT_APP_API_URL;
  const api_key = process.env.REACT_APP_API_KEY;
  const client_id = process.env.REACT_APP_API_CLIENT_ID;

  const { isAuthenticated, token, cid } = useAuth();
  const { getTotalCartItems } = useContext(ShopContext);
  const [isScrolled, setIsScrolled] = useState(false);

  const [customer, setCustomer] = useState({});

  useEffect(() => {
    $('.profile-menu').hide();
  }, []);

  useEffect(() => {
    if (isAuthenticated && cid) {
      fetch(`${api_url}/getcustomer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': api_key,
          'x-client-id': client_id,
        },
        body: JSON.stringify({ cid: cid }),
      })
      .then(res => res.json())
      .then(data => {
        setCustomer(data.customer);
      })
      .catch(err => console.log(err));
    }
  }, [isAuthenticated, cid]);

  const toggleProfileMenu = () => {
    $('.profile-menu').toggle();
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // detect element on which clicked
  document.addEventListener('click', (e) => {
    if (e.target.closest('.profile') || e.target.closest('.profile-img')) {
      // If clicked on profile or profile-img, toggle the menu
      toggleProfileMenu();
    } else if (!e.target.closest('.profile-menu')) {
      $('.profile-menu').hide();
    }
  });

  return (
    <div className={`header ${isScrolled ? 'fixed' : ''}`}>
      <div className='header-main'>
          <div className="header-left">
            <div className="logo">
                <Link to="/"><img src={logo} alt="logo" /></Link>
            </div>
            {/* <Navbar /> */}
          </div>
          <div className="header-right">
              <div className="search" onClick={onSearchClick}>
                  {/* fontawesome search */}
                  {/* <FontAwesomeIcon icon={fas.faSearch} /> */}
                  <i className='bi bi-search'></i>
              </div>
              <Link to="/cart">
                <div className="cart">
                    <img src={cartWhite} alt="" />
                    {getTotalCartItems() > 0 && 
                    <div className="cart-bubble">
                      <span>{getTotalCartItems()}</span>
                    </div>}
                </div>
              </Link>
              <div className="profile">
                <img className='profile-img' src={userWhite} alt="profile" onClick={toggleProfileMenu} />
              </div>
              <div className="profile-menu">
                {isAuthenticated && customer ?
                  <div className="user-info">
                      <h4 className='name'>{customer.first_name}</h4>
                      <span className='email'>{customer.email}</span>
                  </div> : null
                }
                  <div className="menu-items">
                    {isAuthenticated ?
                      <>
                      {/* <div className="profile-menu-item">
                        <Link to="/account" onClick={toggleProfileMenu}>Account Settings</Link>
                      </div>
                      <div className="profile-menu-item">
                        <Link to="/address" onClick={toggleProfileMenu}>Your Addresses</Link>
                      </div> */}
                      <Link to="/orders" onClick={toggleProfileMenu}>
                      <div className="profile-menu-item">
                        Orders
                      </div>
                      </Link>
                      {/* <div className="profile-menu-item">
                        <Link to="/wishlist" onClick={toggleProfileMenu}>Wishlist</Link>
                      </div> */}
                      </> : null
                    }
                    {/* <div className="profile-menu-item">
                      {isAuthenticated ? <Link to="/logout" onClick={toggleProfileMenu}>Logout</Link> : <Link to="/login" onClick={toggleProfileMenu}>Login</Link>}
                    </div> */}
                    {isAuthenticated ?
                      <Link to="/logout" onClick={toggleProfileMenu}>
                        <div className="profile-menu-item">
                          Logout
                        </div>
                      </Link> :
                      <Link to="/login" onClick={toggleProfileMenu}>
                        <div className="profile-menu-item">
                          Login
                        </div>
                      </Link>
                    }
                  </div>
              </div>
            {/* <HamburgerMenu /> */}
          </div>
      </div>
    </div>
  )
}

export default Header
