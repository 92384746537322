import React, { useEffect, useState } from 'react'
import './css/ForgotPassword.css';

const ForgotPassword = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [message, setMessage] = useState('');
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        document.title = 'Forgot Password - Aizen Styles';
        document.querySelector('meta[name="description"]').setAttribute("content", "Reset your Aizen Styles account password.");
    }, []);

    const handleSendResetLink = async (e) => {
        e.preventDefault();
        const email = document.getElementById('email').value;
        if (email) {
            // check if customer email exists
            fetch(`${api_url}/findcustomeremail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ email }),
            })
            .then((res) => res.json())
            // check success true or false
            .then((data) => {
                if (data.success) {
                    setCustomer(data.customer);
                    // send reset link email
                    fetch(`${api_url}/sendresetpasswordemail`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': api_key,
                            'x-client-id': client_id,
                        },
                        body: JSON.stringify({ email }),
                    })
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.success) {
                            document.querySelector('.forgot-password-form').classList.add('hide');
                            document.querySelector('.confirmation').classList.remove('hide');
                        } else {
                            setMessage('An error occurred. Please try again.');
                        }
                    })
                    .catch((err) => {
                        setMessage('An error occurred. Please try again.');
                    });
                } else {
                    setMessage('Email address not found. Please try again.');
                }
            })
            .catch((err) => {
                setMessage('An error occurred. Please try again.');
            });
        } else {
            setMessage('Please enter your email address.');
        }
    }

  return (
    <div className='section-forgot-password'>
        <div className="forgot-password-form">
            <h1 className='section-title'>Forgot Password</h1>
            <div className="message hide">
                <p>{message}</p>
            </div>
            <div action="" className="form">
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" placeholder='Enter your account email' />
                </div>
                <div className="form-group">
                    <button type="submit" className="btn" onClick={handleSendResetLink}>Send Reset Link</button>
                </div>
            </div>
        </div>
        {/* confirmation message after send link clicked */}
        <div className="confirmation hide">
            <h1 className='section-title'>Forgot Password</h1>
            <p className='message'>A password reset link has been sent to your email address. Please check your email and follow the instructions to reset your password.</p>
        </div>
    </div>
  )
}

export default ForgotPassword
