// paymentConfirmation.js

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import OrderConfirmed from '../Components/OrderConfirmed/OrderConfirmed';

const PaymentConfirmation = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const location = useLocation();
    const [paymentVerified, setPaymentVerified] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [paymentMode, setPaymentMode] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const orderId = urlParams.get('orderId');
        const paymentMode = urlParams.get('paymentMode');
        setOrderId(orderId);
        setPaymentMode(paymentMode);
        const confirmPayment = async () => {
            if (paymentMode==='cod'){
                return;
            }
            if (orderId) {
                try {
                    const response = await fetch(`${api_url}/verifypayment`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': api_key,
                            'x-client-id': client_id,
                        },
                        body: JSON.stringify({ orderId }),
                    });
                    const data = await response.json();
                    console.log('Payment confirmation response:', data);
                } catch (error) {
                    console.error('Payment confirmation error:', error);
                    alert('An error occurred while confirming the payment.');
                }
            } else {
                alert('Something went wrong.');
            }
        };
        confirmPayment();

    }, [location]);

    return (
        <div>
            {/* {console.log('Payment Confirmation:', orderId, paymentMode)} */}
            {orderId != null && paymentMode!=null && <OrderConfirmed orderId={orderId} paymentMode={paymentMode}/>}
            {/* {orderId != null && paymentVerified===true && <OrderDetails orderId={orderId}/>} */}
        </div>
    );
};

export default PaymentConfirmation;
