import React, { useEffect, useState } from 'react'
import './css/Login.css'
import { Link } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [logoutStatus, setLogoutStatus] = useState('');
    const [accessToken, setAccessToken] = useState('');

    window.scrollTo(0, 0);
    useEffect(() => {
        document.title = 'Login - Aizen Styles';
        document.querySelector('meta[name="description"]').setAttribute("content", "Login to your Aizen Styles account to access your orders, discover products, and more.");
    }, []);

    const [message, setMessage] = useState('');

    const [formData,setFormData] = useState({email:"",password:""});

    const changeHandler = (e) => {
        setFormData({...formData,[e.target.name]:e.target.value});
    }

    const handleLogin = async () => {
        let dataObj;

        try {
            await fetch(`${api_url}/login`, {
                method: 'POST',
                headers: {
                    Accept:'application/form-data',
                    'Content-Type':'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify(formData),
            })
            .then(response => response.json())
            .then(data => {dataObj = data});
            
            if (dataObj.success) {
                localStorage.setItem('auth-token',dataObj.token);
                window.location.replace("/");
              }
            else{
                setMessage(dataObj.errors);
                const message = document.querySelector('.message');
                message.classList.remove('hide');
            }

        } catch (error) {
            setMessage("Something went wrong. Please try again later.");
            const message = document.querySelector('.message');
            message.classList.remove('hide');
        }

    }
    const handleGoogleLoginSuccess = async (response) => {
        try {
            console.log("Google Login Response:", response);
            const { access_token } = response;
            setAccessToken(access_token);

            // Fetch user info from Google API
            const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            const userInfo = await userInfoResponse.json();
            console.log("Google Login Successful:", userInfo);
            console.log("User Name:", userInfo.name);
            console.log("User Email:", userInfo.email);

            // Handle additional Google login logic (e.g., send token to backend)
        } catch (error) {
            console.error("Error fetching Google user info:", error);
        }
    };

    const handleGoogleLoginError = () => {
        console.error("Google login failed");
    };

    const login = useGoogleLogin({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        onSuccess: handleGoogleLoginSuccess,
        onFailure: handleGoogleLoginError,
        isSignedIn: true,
        accessType: 'offline',
        prompt: 'consent',
        scope: 'openid email profile', // Ensure the scope includes 'openid'
    });

    const revokeToken = async (token) => {
        try {
            const response = await fetch(`https://accounts.google.com/o/oauth2/revoke?token=${token}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            });
            if (response.ok) {
                console.log("Token revoked successfully");
                return true;
            } else {
                console.error("Failed to revoke token");
                return false;
            }
        } catch (error) {
            console.error("Error revoking token:", error);
            return false;
        }
    };

    const handleGoogleLogout = async () => {
        const success = await revokeToken(accessToken);
        if (success) {
            googleLogout();
            setLogoutStatus('Logout successful');
        } else {
            setLogoutStatus('Logout failed');
        }
    };


  return (
    <div className='section-login'>
        <div className="login-form">
            <h1 className="section-title">Log In</h1>
            <div className="message hide">
                <p>{message}</p>
            </div>
            <div action="" className="form">
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" onChange={changeHandler} value={formData.email}/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" onChange={changeHandler} value={formData.password}/>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn" onClick={handleLogin}>Login <FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
            </div>
            <div className="cta-action">
                <div className="forgot-password">
                    <p>Forgot Password? <Link to="/forgotpassword">Reset</Link></p>
                </div>
                <div className="go-to-register">
                    <p>Don't have an account? <Link to="/register">Register</Link></p>
                </div>
            </div>
            <div className="line"></div>
            <div className="google-login-wrapper">
                <button className="google-login-button" onClick={() => login()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4 text-neutral-800 dark:text-neutral-300"><path d="M20.945 11a9 9 0 1 1 -3.284 -5.997l-2.655 2.392a5.5 5.5 0 1 0 2.119 6.605h-4.125v-3h7.945z"></path></svg>
                Google
                </button>
            </div>
        </div>
    </div>
  )
}

export default Login
