// import React from 'react'
// import './Hero.css'
// import $ from 'jquery'

// import hoodie from '../Assets/Mockups/hoodie_transparent.png'

// const Hero = (props) => {
//     const handleCta = () => {
//         $('html, body').animate({
//             scrollTop: $('.new-arrivals').offset().top
//         }, 1000);
//     }
//   return (
//     <div className='hero'>
//         <div className="back-text">
//             <div className='main-text text-container'>
//                 <span className='text'>Grunge</span>
//             </div>
//             <div className="sub-text text-container">
//                 <span className='text'>Collection</span>
//             </div>
//         </div>
//         <div className="product-image">
//             <img src={hoodie} alt="Hoodie" />
//         </div>
//         <div className='story'>
//             <h1 className="story-title">Journey</h1>
//             <div className="story-description">
//                 <p>Our grunge collection is inspired by the 90s grunge movement. We've taken the best elements of the grunge movement and combined them with modern design to create a collection that is both nostalgic and contemporary. Our grunge collection is perfect for anyone who loves the grunge aesthetic and wants to add a touch of grunge to their wardrobe.</p>
//             </div>
//             <button className='btn cta' onClick={handleCta}>Buy Now</button>
//         </div>
//         {/* horizontal line */}
//         <div className="line"></div>
//     </div>
//   )
// }

// export default Hero










import React, { useState, useEffect, useRef } from 'react';
import './Hero.css';
import $ from 'jquery';
import hoodie from '../Assets/Mockups/hoodie_transparent.png';

// typing animation
import Typewriter from 'typewriter-effect';

const Hero = (props) => {
    const [rotation, setRotation] = useState({ x: 0, y: 0 });
    const [translateZ, setTranslateZ] = useState(0);

    const handleMouseMove = (e) => {
        const { innerWidth, innerHeight } = window;
        const xRotation = ((e.clientY / innerHeight) - 0.5) * -20; // Rotate vertically
        const yRotation = ((e.clientX / innerWidth) - 0.5) * 30; // Rotate horizontally

        setRotation({ x: xRotation, y: yRotation });
        setTranslateZ(60);
    };

    const handleMouseLeave = () => {
        // Reset rotation when the mouse leaves the container
        setRotation({ x: 0, y: 0 });
        setTranslateZ(0);
    };

    // touch event
    const handleTouchMove = (e) => {
        const { innerWidth, innerHeight } = window;
        const xRotation = ((e.touches[0].clientY / innerHeight) - 0.5) * -20; // Rotate vertically
        const yRotation = ((e.touches[0].clientX / innerWidth) - 0.5) * 30; // Rotate horizontally

        setRotation({ x: xRotation, y: yRotation });
        setTranslateZ(60);
    };

    const handleTouchEnd = () => {
        // Reset rotation when the touch ends
        setRotation({ x: 0, y: 0 });
        setTranslateZ(0);
    };

    const handleCta = () => {
        $('html, body').animate(
            { scrollTop: $('.new-arrivals').offset().top },
            1000
        );
    };

    const textRef = useRef(null);
  const containerRef = useRef(null);

  const adjustFontSize = () => {
    if (textRef.current && containerRef.current) {
      let fontSize = 17.8; // Initial large font size in viewport units
      textRef.current.style.fontSize = fontSize + 'vw'; // Set initial size

      // Adjust font size until the text fits within the container
      while (textRef.current.offsetWidth > containerRef.current.offsetWidth) {
        fontSize -= 1; // Decrease font size incrementally
        textRef.current.style.fontSize = fontSize + 'vw';
      }
    }
  };

  useEffect(() => {
    // Call the function once on initial load
    adjustFontSize();

    // Adjust font size on window resize
    window.addEventListener('resize', adjustFontSize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', adjustFontSize);
    };
  }, []);

    return (
        <div
            className='hero'
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave} // Reset rotation on mouse leave
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd} // Reset rotation on touch end
            ref={containerRef}>
            <div className="back-text">
                <div className='main-text text-container'>
                    <span className='text' ref={textRef}>
                        <Typewriter
                        options={{
                            strings: [
                            props.main_text,
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 200,
                            pauseFor: 100000,
                            deleteSpeed: 50,
                        }}
                        />
                    </span>
                </div>
                <div className="sub-text text-container">
                    <span className='text'>
                        <Typewriter
                        options={{
                            strings: [
                            props.sub_text,
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 120,
                            pauseFor: 100000,
                            deleteSpeed: 50,
                        }}
                        />
                    </span>
                </div>
            </div>
            <div className="product-image" style={{
                transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg) translateZ(${translateZ}px)`, transition: 'transform 0.3s ease-out'}}>
                <img src={hoodie} alt="Hoodie" />
            </div>
            <div className='story'>
                <h1 className="story-title">{props.story_title}</h1>
                <div className="story-description">
                    <p>{props.story}</p>
                </div>
                <button className='btn cta' onClick={handleCta}>{props.cta}</button>
            </div>
            <div className="line"></div>
        </div>
    );
};

export default Hero;
