// src/hooks/useAuth.js
import { useState, useEffect } from 'react';

const useAuth = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const api_key = process.env.REACT_APP_API_KEY;
  const client_id = process.env.REACT_APP_API_CLIENT_ID;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const token = localStorage.getItem('auth-token');
  const [cid, setCid] = useState(null);

  const verifyToken = async () => {
    let dataObj;

    if (!isVerified) {
      if (!isAuthenticated) {
        if (token === null) {
          // window.location.replace('/login');
        } else {
          try {
            const response = await fetch(`${api_url}/protected-route`, {
              method: 'GET',
              headers: {
                'x-api-key': api_key,
                'x-client-id': client_id,
                'authorization': token,
              },
            });
            dataObj = await response.json();
            setCid(dataObj.cid);
            if (dataObj.success) {
              setIsAuthenticated(true);
            }
            // else {
            //   window.location.replace('/login');
            // }
          } catch (error) {
            // window.location.replace('/login');
          }
        }
        setIsVerified(true);
      }
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return { isAuthenticated, isVerified, token, verifyToken, cid };
};

export default useAuth;