import React, { useEffect } from 'react';
import $ from 'jquery'; // Import jQuery
import instagram from '../Assets/icons/instagram-black.png';
import whatsapp from '../Assets/icons/whatsapp-black.png';
import instagramRed from '../Assets/icons/instagram-red.png';
import whatsappGreen from '../Assets/icons/whatsapp-green.png';
import './SocialFloat.css';

const SocialFloat = () => {
  useEffect(() => {
    const animateSocialFloat = () => {
      $('.icon1').fadeToggle('slow');
      $('.icon2').fadeToggle('slow');
    };

    const animateFLoatInterval = setInterval(animateSocialFloat, 1500); 

    return () => clearInterval(animateFLoatInterval);
    
  }, []);

  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleSocialDiv = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {

    const increaseHeight = () => {
      if (isExpanded) {
        $('.open-social').css('transform', 'rotate(360deg)');
        $('.social-con').animate({height: '+=150px'}, 'slow', function(){
          $('.social-div img').fadeIn('slow');
        });
      } else {
        $('.open-social').css('transform', 'rotate(0deg)');
        $('.social-div img').fadeOut('slow', function(){
          $('.social-con').animate({height: '-=150px'}, 'slow');
        });
      }
    };

    increaseHeight();
  }, [isExpanded]);

  const handleSocialClick = (social) => {
    if (social === 'whatsapp') {
      window.open('https://wa.me/message/PQRPAPU4JDKWP1', '_blank');
    } else {
      window.open('https://www.instagram.com/aizen.styles/', '_blank');
    }
    setIsExpanded(false);
  }

  return (
    <div className="floating-social">
        <div className="social-con">
          <div className="social-div">
            <div className="instagram">
              <img src={instagramRed} alt="" onClick={()=>handleSocialClick("instagram")} />
            </div>
            <div className="whatsapp">
              <img src={whatsappGreen} alt="" onClick={()=>handleSocialClick("whatsapp")} />
            </div>
          </div>
          <div className="open-social" onClick={toggleSocialDiv}>
            <img src={instagram} alt="" className='icon icon1'/>
            <img src={whatsapp} alt="" className='icon icon2'/>
          </div>
        </div>
    </div>
  )
}

export default SocialFloat
