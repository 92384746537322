import React, { useEffect } from 'react'
import './css/Policy.css'

const RefundCancellation = () => {

  useEffect(() => {
    document.title = 'Refund & Cancellation - Aizen Styles';
    document.querySelector('meta[name="description"]').setAttribute("content", "At Aizen Styles, we strive to ensure your satisfaction with every purchase. Here is our refund and cancellation policy.");
  }, []);

  window.scrollTo(0, 0);
  const email = 'support@aizenstyles.in';

  const handleMailClick = (event) => {
    event.preventDefault();
    const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;

    window.open(gmailComposeUrl, '_blank');
  };
  return (
    <div className='refund-cancellation policy'>
      <div className="section-head">
          <h1 className='title'>Refund &<br />Cancellation</h1>
          <p className='description'>At Aizen Styles, customer satisfaction is our priority. To be eligible for a refund, the following conditions must be met.</p>
      </div>
      <div className="refund-eligibility rule">
        <div className="head">
          <div className='title'>Refund Eligibility</div>
          <div className="line"></div>
          <div className="hashtag">#1</div>
        </div>
        <div className="body">
          <p>Refunds are processed solely in the case of mistakes or defects that are attributed to Aizen Styles. We take responsibility for any errors that occur on our part.</p>
        </div>
      </div>
      <div className="client-side-situations rule">
        <div className="head">
          <div className='title'>Client-Side Situations</div>
          <div className="line"></div>
          <div className="hashtag">#2</div>
        </div>
        <div className="body">
          <p>Unfortunately, we cannot entertain refund requests for situations arising from the client side, such as change of mind, wrong size ordered, or any other circumstances not related to errors or defects on our part.</p>
        </div>
      </div>
      <div className="cancellation-policy rule">
        <div className="head">
          <div className='title'>Cancellation Policy</div>
          <div className="line"></div>
          <div className="hashtag">#3</div>
        </div>
        <div className="body">
          <span className='sub-title'>Timeframe</span>
          <p>Orders can be canceled within 24 hours of placing the order. After this period, the cancellation option may not be available.</p>
          <span className='sub-title'>Cancellation Process</span>
          <p>To cancel an order, please contact our customer service team at <a onClick={handleMailClick}>{email}</a> or call us at <a href="tel:+919311852353">+919311852353</a> within the stipulated timeframe.</p>
        </div>
      </div>
      <div className="refund-processing-time rule">
        <div className="head">
          <div className='title'>Refund Processing Time</div>
          <div className="line"></div>
          <div className="hashtag">#4</div>
        </div>
        <div className="body">
          <span className='sub-title'>Processing Time</span>
          <p>Refunds are typically processed within 1 to 2 working days.</p>
          <span className='sub-title'>Notification</span>
          <p>Once your refund is processed, you will receive a notification confirming the credit.</p>
          <span className='sub-title'>Bank Processing Time</span>
          <p>Please note that the time taken for the refunded amount to reflect in your bank account may vary based on your bank's policies. On average, it takes 5 to 7 working days for the credited amount to appear in your account.</p>
        </div>
      </div>
      <div className="contact rule">
        <div className="head">
          <div className='title'>Contact</div>
          <div className="line"></div>
          <div className="hashtag">#5</div>
        </div>
        <div className="body">
          <p>For any questions or concerns regarding refunds or cancellations, please reach out to our customer service team.</p>
        </div>
      </div>
    </div>
  )
}

export default RefundCancellation
