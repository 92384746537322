import React, { useEffect } from 'react';
import './css/Policy.css';

import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Terms & Conditions - Aizen Styles';
    document.querySelector('meta[name="description"]').setAttribute("content", "Welcome to Aizen Styles! Please read these terms and conditions carefully before using our website. By accessing or using the Aizen Styles website, you agree to comply with and be bound by the following terms and conditions of use. If you do not agree with any part of these terms, please do not use our website.");
  }, []);

  window.scrollTo(0, 0);
  const email = 'support@aizenstyles.in';

  const handleMailClick = (event) => {
    event.preventDefault();
    const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;

    window.open(gmailComposeUrl, '_blank');
  };
  return (
    <div className='terms-and-conditions policy'>
        <div className="section-head">
            <h1 className='title'>Terms &<br /> Conditions</h1>
            <p className='description'>Welcome to Aizen Styles! Please read these terms and conditions carefully before using our website.<br />By accessing or using the Aizen Styles website, you agree to comply with and be bound by the following terms and conditions of use. If you do not agree with any part of these terms, please do not use our website.</p>
        </div>
        <div className="privacy-policy rule">
          <div className="head">
            <div className='title'>Privacy Policy</div>
            <div className="line"></div>
            <div className="hashtag">#1</div>
          </div>
          <div className="body">
            <p>Please review our <Link to="/policy/privacy-policy">Privacy Policy</Link> to understand how we collect, use, and safeguard your personal information.</p>
          </div>
        </div>
        <div className="products-and-services rule">
          <div className="head">
            <div className='title'>Products and Services</div>
            <div className="line"></div>
            <div className="hashtag">#2</div>
          </div>
          <div className="body">
            <span className='sub-title'>Product Description</span>
            <p>We make every effort to provide accurate and detailed product descriptions. However, we do not warrant that product descriptions or other content on the site are accurate, complete, reliable, current, or error-free.</p>
            <span className='sub-title'>Pricing</span>
            <p>All prices are listed in rupees (₹). Prices are subject to change without notice.</p>
          </div>
        </div>
        <div className="orders-and-payments rule">
          <div className="head">
            <div className='title'>Orders and Payments</div>
            <div className="line"></div>
            <div className="hashtag">#3</div>
          </div>
          <div className="body">
            <span className='sub-title'>Order Acceptance</span>
            <p>All orders are subject to acceptance and availability. We reserve the right to refuse or cancel an order for any reason but not without notifying the customer in any way.</p>
            <span className='sub-title'>Payment</span>
            <p>We accept Cash on Delivery, UPI, Credit card, Debit Card, Net Banking. Payments are processed securely, and your payment information is not stored.</p>
          </div>
        </div>
        <div className="shipping-and-delivery rule">
          <div className="head">
            <div className='title'>Shipping and Delivery</div>
            <div className="line"></div>
            <div className="hashtag">#4</div>
          </div>
          <div className="body">
            <span className='sub-title'>Shipping Times</span>
            <p>Shipping times may vary based on your location. Please refer to our <Link to="/policy/shipping-policy">Shipping Policy</Link> for more information.</p>
            <span className='sub-title'>Delivery</span>
            <p>We are not responsible for any delays caused by third-party delivery services.</p>
          </div>
        </div>
        <div className="returns-and-exchanges rule">
          <div className="head">
            <div className='title'>Returns and Exchanges</div>
            <div className="line"></div>
            <div className="hashtag">#5</div>
          </div>
          <div className="body">
            <span className='sub-title'>Return Policy</span>
            <p>Please refer to our <Link to="/policy/return-policy">Return Policy</Link> for information on returns and exchanges.</p>
            <span className='sub-title'>Conditions</span>
            <p>Returned items must be in their original condition, unworn, and with all tags attached.</p>
          </div>
        </div>
        <div className="intellectual-property rule">
          <div className="head">
            <div className='title'>Intellectual Property</div>
            <div className="line"></div>
            <div className="hashtag">#6</div>
          </div>
          <div className="body">
            <span className='sub-title'>Copyright</span>
            <p>All content on this site, including but not limited to text, graphics, logos, images, and software, is the property of Aizen Styles and is protected by copyright laws.</p>
            <span className='sub-title'>Trademarks</span>
            <p>Aizen Styles and its logo are trademarks owned by Aizen. Any use of these trademarks without our express written consent is strictly prohibited.</p>
          </div>
        </div>
        <div className="limitation-of-liability rule">
          <div className="head">
            <div className='title'>Limitation of Liability</div>
            <div className="line"></div>
            <div className="hashtag">#7</div>
          </div>
          <div className="body">
            <p>We are not liable for any direct, indirect, incidental, special, or consequential damages arising from the use of our website or the purchase of our products.</p>
          </div>
        </div>
        <div className="governing-law rule">
          <div className="head">
            <div className='title'>Governing Law</div>
            <div className="line"></div>
            <div className="hashtag">#8</div>
          </div>
          <div className="body">
            <p>These terms and conditions are governed by and construed in accordance with the laws of India.</p>
          </div>
        </div>
        <div className="contact rule">
          <div className="head">
            <div className='title'>Contact</div>
            <div className="line"></div>
            <div className="hashtag">#9</div>
          </div>
          <div className="body">
            <p>If you have any questions about our terms and conditions, please contact us at <a onClick={handleMailClick}>{email}</a>.</p>
          </div>
        </div>
    </div>
  )
}

export default TermsAndConditions
