import React from 'react'
import './Account.css'

const Account = () => {
  return (
    <div className='section-account'>
        <div className="account-details">
            <h1 className="section-title">Account Details</h1>
            <form action="" className="form">
                <div className="form-group">
                    <label htmlFor="fullname">Full Name</label>
                    <input type="text" name="fullname" id="fullname"/>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email"/>
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input type="text" name="phone" id="phone"/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" />
                </div>
                <div className="form-group">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input type="password" name="confirm-password" id="confirm-password" />
                </div>
                <div className="form-group">
                    <button type="submit" className="btn">Update</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Account
