// import React from 'react'
// import './Item.css'

// import { Link } from 'react-router-dom'

// const Item = (props) => {
//   return (
//     <div className='item'>
//         <div className="item-img">
//             <Link to={`/product/${props.pid}`}>
//                 <img src={props.colors[0].images[0]} alt="" />
//             </Link>
//         </div>
//         {/* <div className="item-details">
//             <div className="item-info">
//                 <Link to={`/product/${props.pid}`}>
//                     <span className='item-name'>{props.name}</span>
//                     <div className="item-price">
//                         <span className='sp'>&#8377; {props.colors[0].sizes[0].sellingPrice} </span>
//                         <span className='mrp'>&#8377; {props.colors[0].sizes[0].mrp} </span>
//                     </div>
//                 </Link>
//             </div>
//         </div> */}
//     </div>
//   )
// }

// export default Item



import React, { useState, useEffect } from 'react';
import './FreeformGridItem.css';
import { Link } from 'react-router-dom';

const FreeformGridItem = (props) => {
  const [itemHeight, setItemHeight] = useState('400px'); // Default height for desktop

  // Function to get random height based on screen width
  const getRandomHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth > 1000) {
      // Random height for large screens
      return `${Math.floor(Math.random() * (500 - 400 + 1)) + 400}px`;
    } else if (screenWidth > 850) {
      return `${Math.floor(Math.random() * (500 - 350 + 1)) + 350}px`;
    } else if (screenWidth > 700) {
      return `${Math.floor(Math.random() * (450 - 250 + 1)) + 300}px`;
    } else if (screenWidth > 640) {
      return `${Math.floor(Math.random() * (450 - 400 + 1)) + 300}px`;
    } else {
      return `${Math.floor(Math.random() * (300 - 150 + 1)) + 150}px`;
    }
  };

  // Set random height on component mount
  useEffect(() => {
    setItemHeight(getRandomHeight());
    console.log('Item height:', itemHeight);
  }, []);

  return (
    <div className='freeform-grid-item' style={{ height: itemHeight }}>
      <div className="item-img">
        <Link to={`/product/${props.pid}`}>
          <img src={props.colors[0].images[0]} alt={props.name} />
        </Link>
      </div>
    </div>
  );
};

export default FreeformGridItem;
