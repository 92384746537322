import React, {useEffect, useState} from 'react';
import './Overlay.css'; // Create a CSS file for overlay styles

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fas from '@fortawesome/free-solid-svg-icons';

import product1 from '../Assets/Mockups/transparent/1.png';

const Overlay = ({ isVisible, onClose }) => {

    const [search, setSearch] = useState('');
    
    useEffect(() => {
        if (isVisible){
            document.getElementById('search').focus();
        }
    }, [isVisible]);

    const handleSearchQuery = () => {
        const query = encodeURIComponent(search.toLowerCase());
        setSearch('');
        window.location.href = `/search?query=${query}`;
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchQuery();
        }
    }
    const changeHanler = (e) => {
        setSearch(document.getElementById('search').value);
    }

    const handleClick = (e) => {
        if (e.target.classList.contains('overlay') && !e.target.closest('.search-box')) {
            onClose();
        }
    }

    if (!isVisible) return null;
    
    return (
    <div className="overlay" onClick={handleClick}>
      <div className="overlay-content">
        <div className="search-box">
            <div className="search-head">
                <div className="search-bar">
                    <input type="text" name="search" id="search" value={search} onChange={changeHanler} onKeyDown={handleKeyDown} placeholder='Search' />
                </div>
                <div className="search-btn" onClick={handleSearchQuery}>
                    <FontAwesomeIcon icon={fas.faSearch} />
                </div>
            </div>
            <div className="suggestion-container">
                <div className="suggestion"></div>
                <div className="suggestion"></div>
            </div>
            <div className="recommendation-container">
                <div className="recommendation">
                    <div className="product-image">
                        <img src={product1} alt="product" />
                    </div>
                    <div className="product-title">
                        <p>Hoodie#1</p>
                    </div>
                </div>
                <div className="recommendation">
                    <div className="product-image">
                        <img src={product1} alt="product" />
                    </div>
                    <div className="product-title">
                        <p>Hoodie#2</p>
                    </div>
                </div>
                <div className="recommendation">
                    <div className="product-image">
                        <img src={product1} alt="product" />
                    </div>
                    <div className="product-title">
                        <p>Hoodie#3</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    );
};

export default Overlay;