import React from 'react'
import './Address.css'
import { Link } from 'react-router-dom'

const Address = () => {
  return (
    <div className='section-address'>
        <div className="address-con">
            <h1 className="section-title">Your Addresses</h1>
            <div className="address">
                <div className="address-info">
                    <h2 className='address-title'>Address 1</h2>
                    <p><span className="bold">Name: </span> John Doe</p>
                    <p><span className="bold">Phone Number: </span> +9199372833987</p>
                    <p><span className="bold">Address: </span> 123, Main Street, New York, USA</p>
                </div>
                <div className="address-actions">
                    <button className="editBtn btn">Edit</button>
                    <button className="deleteBtn btn">Delete</button>
                </div>
            </div>
            <div className="address">
                <div className="address-info">
                    <h2 className='address-title'>Address 2</h2>
                    <p><span className="bold">Name: </span> John Doe</p>
                    <p><span className="bold">Phone Number: </span> +9199372833987</p>
                    <p><span className="bold">Address: </span> 123, Main Street, New York, USA</p>
                </div>
                <div className="address-actions">
                    <button className="editBtn btn">Edit</button>
                    <button className="deleteBtn btn">Delete</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Address
