import React, { useEffect } from 'react'
import './css/Home.css'

import Hero from '../Components/Hero/Hero'
import FeaturedProducts from '../Components/FeaturedProducts/FeaturedProducts'
import SocialFloat from '../Components/SocialFloat/SocialFloat'
import ComingSoon from '../Components/ComingSoon/ComingSoon'

const Home = () => {

  useEffect(() => {
    // search engine friendly content
    document.title = 'Aizen Styles: Online Genz Fashion Shopping For Men, Women, Unisex. One Stop Shop For Streetwear Fashion and Anime Lovers, Shop Now!';
    document.querySelector('meta[name="description"]').setAttribute("content", "Shop the latest fashion trends at Aizen Styles an online shopping platform. Discover the latest trends in the world of fashion. We offer a wide range of products ranging from streetwear to fashion for anime lovers.");

  }, []);

  return (
    <div className='home'>
        <Hero main_text="Grunge" sub_text="Collection" cta="Shop Now" story="Our grunge collection is inspired by the 90s grunge movement. We've taken the best elements of the grunge movement and combined them with modern design to create a collection that is both nostalgic and contemporary. Our grunge collection is perfect for anyone who loves the grunge aesthetic and wants to add a touch of grunge to their wardrobe." story_title="Journey" />
        <FeaturedProducts/>
        <SocialFloat/>
    </div>
  )
}

export default Home
